import { TextField } from "@mui/material";
import React from 'react';
import "./text-field-component.css";

type TextFieldProps = {
    id: string,
    type: React.HTMLInputTypeAttribute,
    required: boolean,
    label: string,
    value: string | number | null,
    error: boolean,
    helperText: string,
    disabled: boolean,
    setValueString?: (value: string) => void;
    setValueNumber?: (value: number) => void;
    setValueNull?: (value: null) => void,
    setValueChanged?: (val: boolean) => void;
};

const TextFieldComponentSubmitButton = ({
    id,
    required,
    type,
    label,
    value,
    error,
    helperText,
    disabled,
    setValueString,
    setValueNumber,
    setValueNull,
    setValueChanged
}: TextFieldProps) => {
    return <TextField
        sx={{
            color: "#EEEEEE",
            width: "20em",
            height: "0px",
            marginRight: "1em",
            paddingBottom: "4em",
            input: { color: '#EEEEEE' },
            "& .MuiInputBase-input": { backgroundColor: "#1c2764" },
        }}
        InputLabelProps={{ className: "textfield__label_submit_button" }}
        required={required}
        id={id}
        label={label}
        type={type}
        value={value}
        className="single-view__text-field"
        variant="filled"
        error={error}
        helperText={
            helperText
        }
        disabled={disabled}
        onChange={(event) => {
            if (setValueString) {
                setValueString(event.target.value);
            } else if (setValueNumber) {
                setValueNumber(+event.target.value);
            } else if (setValueNull) {
                setValueNull(null);
            }
            if (setValueChanged) {
                setValueChanged(true);
            }
        }}
    />
}

export default TextFieldComponentSubmitButton;