import { useParams } from "react-router-dom";
import PageLayout from "../../../shared/components/page-layout";
import { useEffect, useState } from "react";
import { AmountToPayDetailAPI, YearToPayData } from "../../../models/admin";
import { fetchAllAmountsToPayForSingleInstitutions } from "../../../shared/requests/admin-request";
import { axisClasses, BarChart } from "@mui/x-charts";
import { DatasetElementType } from "@mui/x-charts/internals";
import AccordionComponent from "../../../shared/components/accordion-component";
import { BarChartOutlined, CalendarMonth } from "@mui/icons-material";
import { Divider, List, ListItem, ListItemText } from "@mui/material";

function CostDetail() {
    const [costData, setCostData] = useState<AmountToPayDetailAPI | null>(null);
    const [chartData, setChartData] = useState<DatasetElementType<string | number>[]>([]);
    const [yearData, setYearData] = useState<YearToPayData[]>([])
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            fetchAllAmountsToPayForSingleInstitutions(id).then(response => {
                setCostData(response.data)
                const chart: DatasetElementType<string | number>[] = [];
                const yearValues: YearToPayData[] = [];
                response.data.monthValues.forEach((element) => {
                    let entry = yearValues.find(val => val.year === element.year)
                    if (entry != null) {
                        entry.amountCases += element.amountCases;
                        entry.amountToPay += element.amountToPay;
                    } else {
                        yearValues.push({
                            year: element.year,
                            amountCases: element.amountCases,
                            amountToPay: element.amountToPay
                        });
                    }
                    chart.push({
                        date: element.month + "/" + element.year,
                        amountToPay: element.amountToPay,
                        amountCases: element.amountCases
                    })
                })
                setChartData(chart.sort((a, b) => a.date > b.date ? 1 : -1))
                setYearData(yearValues.sort((a, b) => a.year > b.year ? 1 : -1))
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const otherSetting = {
        height: 500,
        yAxis: [{ label: 'Kosten in €' }],
        grid: { horizontal: true },
        sx: {
            margin: 1,
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',

            },
        },
    };


    return <PageLayout title={`Kostenaufstellung Beihilfestelle ${costData !== null ? costData.aid.name : ""}`} actions={<div></div>}>
        <div className="single-view">
            {chartData !== null && chartData.length > 0 &&
                <div className='single-view-accordion'>
                    <AccordionComponent
                        defaultExpanded={true}
                        label='Kostengrafik'
                        error={false}
                        startIcon={<BarChartOutlined />}
                        content={
                            <BarChart
                                dataset={chartData}
                                xAxis={[
                                    {
                                        scaleType: 'band',
                                        dataKey: 'date',
                                        valueFormatter: (value) => {
                                            return value
                                        },
                                        label: "Monat/Jahr"
                                    },
                                ]}
                                series={[{ dataKey: 'amountToPay', label: 'Kosten für Berechnungen' }, { dataKey: 'amountCases', label: 'Anzahl Fälle' }]}
                                {...otherSetting}
                            />}
                    />
                </div>
            }
            {costData !== null && costData.monthValues !== null && costData.monthValues.length > 0 &&
                <div className='single-view-accordion'>
                    <AccordionComponent
                        defaultExpanded={false}
                        label='Monatliche Kosten'
                        error={false}
                        startIcon={<CalendarMonth />}
                        content={
                            <List
                                sx={{
                                    width: '100%',
                                    height: "100",
                                    bgcolor: 'background.paper',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 500,
                                    '& ul': { padding: 0 },
                                }}
                                aria-label="month costs"
                            >
                                {costData.monthValues.sort((a, b) => {
                                    if (a.year > b.year) {
                                        return 1;
                                    }
                                    if (a.year < b.year) {
                                        return -1;
                                    }
                                    if (a.month > b.month) {
                                        return 1;
                                    }
                                    if (a.month < b.month) {
                                        return -1;
                                    }
                                    return 0;
                                }).map((value) => {
                                    return <div>
                                        <ListItem disablePadding key={value.month + "/" + value.year} sx={{ background: "#EEEEEE" }}>

                                            <ListItemText primary={"Monat: " + value.month + "/" + value.year + " | Anzahl Fälle: " + value.amountCases + " | Entstandene Kosten: " + value.amountToPay + "€"} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                        </ListItem>
                                        <Divider></Divider>
                                    </div>
                                })}
                            </List>
                        }
                    />
                </div>
            }
            {yearData !== null && yearData.length > 0 &&
                <div className='single-view-accordion'>
                    <AccordionComponent
                        defaultExpanded={false}
                        label='Jährliche Kosten'
                        error={false}
                        startIcon={<CalendarMonth />}
                        content={
                            <List
                                sx={{
                                    width: '100%',
                                    height: "200px",
                                    bgcolor: 'background.paper',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 500,
                                    '& ul': { padding: 0 },
                                }}
                                aria-label="year costs"
                            >
                                {yearData.sort((a, b) => {
                                    if (a.year > b.year) {
                                        return 1;
                                    }
                                    if (a.year < b.year) {
                                        return -1;
                                    }
                                    return 0;
                                }).map((value) => {
                                    return <div>
                                        <ListItem disablePadding key={value.year} sx={{ background: "#EEEEEE" }}>

                                            <ListItemText primary={"Jahr: " + value.year + " | Anzahl Fälle: " + value.amountCases + " | Entstandene Kosten: " + value.amountToPay + "€"} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                        </ListItem>
                                        <Divider></Divider>
                                    </div>
                                })}
                            </List>
                        }
                    />
                </div>
            }
            {(chartData === null || chartData.length === 0) &&
                < div className="flex flex-row single-view__form-row-width">Für diese Beihilfestelle sind noch keine Kosten angefallen!</div>
            }
        </div>
    </PageLayout>
}


export default CostDetail;