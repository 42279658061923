import { Dayjs } from "dayjs";
import { AddressAPI } from "../models/address";
import { CalculationBasisAPI, getCalculationBasisAmountFlag } from "../models/calculation-basis";
import { CalculationCaringPersonAPI } from "../models/caring-person";
import { getStatusValues, getValidityValues } from "../models/historization";
import { getInstitutionTypesWithoutSystem, InstitutionAPI } from "../models/institution";
import { PersonAPI } from "../models/person";
import { getAllUserRoles, getUserActiveTypes, UserAPI } from "../models/user";

export const formatDateString = (dateString: string) => {
    var date = new Date(dateString);
    return formatDate(date)
}

export const formatDateStringDayJsYearMonth = (date: Dayjs | null) => {
    if (date === null) {
        return ""
    }
    return formatDateString(formatDayJsMonthYearToFirstDayOfMonthString(date))
}

export const formatDate = (date: Date) => {
    return (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
        (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
        date.getFullYear();
}

export const formatCurrentDateForFileOutput = () => {
    var date = new Date();
    return date.getFullYear() + (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + (date.getDate() < 10 ? "0" : "") + date.getDate()
}

export const formatYearMonthForFileOutput = (dateString: string) => {
    var date = new Date(dateString);
    return date.getFullYear() + (date.getMonth() < 10 ? "0" : "") + (date.getMonth())
}


export const formatMonthString = (dateString: string) => {
    var date = new Date(dateString);
    return (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
        date.getFullYear();
}

export const formatTimestampString = (timestampString: string | null) => {
    if (timestampString != null && timestampString.length > 0) {
        var date = new Date(timestampString);
        return formatDateString(timestampString) + " " +
            (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
            (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    }
    return "";
}

export const formatFromUntilStringMonth = (calculation: CalculationCaringPersonAPI) => {
    if (calculation != null) {
        if (calculation.startMonth !== calculation.endMonth) {
            return calculation.startMonth + "-" + calculation.endMonth
        } else {
            return String(calculation.startMonth)
        }
    } else {
        return ""
    }
}

export const formatFromUntilStringYear = (calculation: CalculationCaringPersonAPI) => {
    if (calculation != null) {
        if (calculation.startYear !== calculation.endYear) {
            return calculation.startYear + "-" + calculation.endYear
        } else {
            return String(calculation.startYear)
        }
    } else {
        return ""
    }
}

export const formatPersonName = (person: PersonAPI) => {
    let dateOfBirth = null;
    if (person.dateOfBirth !== null) {
        dateOfBirth = formatDateString(person.dateOfBirth)
    }
    return person.lastname + ", " + person.firstname + (person.dateOfBirth !== null ? `* ${dateOfBirth}` : "")
}

export const formatAddressString = (address: AddressAPI | null) => {
    if (address !== null) {
        return (address.street !== "" ? address.street : "") + (address.houseNumber !== null ? " " + address.houseNumber : "") + (address.street !== null || address.houseNumber !== null ? " | " : "") + address.postalCode + " " + address.location
    }
    return "Unbekannt";
}

export const formatValidityString = (validityString: string) => {
    const elementValidty = getValidityValues().find((ele) => { return validityString === ele.id; });
    return elementValidty ? elementValidty.name : "Ohne Gültigkeitskennzeichen"
}

export const formatStatusString = (statusString: string, user: UserAPI | null) => {
    const elementStatus = getStatusValues().find((ele) => { return statusString === ele.id; });
    if (user === null) {
        return elementStatus ? elementStatus.name === "Schwebend" ? elementStatus.name : "" : ""
    }
    return elementStatus ? elementStatus.name === "Schwebend" ? `${elementStatus.name} (${user.lastname}, ${user.firstname})` : "" : ""
}

export const formatInstitutionTypeString = (institutionString: string, noTypeString: string) => {
    const element = getInstitutionTypesWithoutSystem().find((element) => { return institutionString === element.id; });
    return element ? element.name : noTypeString
}

export const formatSuccessorString = (successor: InstitutionAPI | null) => {
    return successor ? String(successor.name) : "Ohne Nachfolger";
}

export const formatActiveString = (active: Boolean) => {
    const ele = getUserActiveTypes().find((ele) => { return active === ele.realValue; });
    return ele ? ele.name : "Ohne Kontostatus"
}

export const formatRoleString = (roleString: string) => {
    const elementRole = getAllUserRoles().find((ele) => { return roleString === ele.id; });
    return elementRole ? elementRole.name : "Ohne Benutzerrolle"
}

export const addCurrencySymbol = (beforeNumber: number) => {
    var numb = beforeNumber.toFixed(2)
    return numb + "€"
}

export const getFromUntilStringFromCalculationBasisList = (list: CalculationBasisAPI[]) => {
    var lowestStartDate: Date;
    var highestEndDate: Date | null;
    if (list.length === 0) {
        return "";
    } else {
        list = list.sort((a, b) => a.from > b.from ? 1 : -1);
        lowestStartDate = new Date(list[0].from)
        list = list.sort((a, b) => a.until === null ? -1 : b.until === null ? 1 : a.until > b.until ? -1 : 1);
        if (list[0].until !== null) {
            highestEndDate = new Date(list[0].until)
        } else {
            highestEndDate = null;
        }
    }
    if (highestEndDate !== null) {
        return formatDate(lowestStartDate) + " - " + formatDate(highestEndDate);
    } else {
        return formatDate(lowestStartDate) + " - Offen";
    }

}

export const formatCalculationBasisListEntry = (calculationBasis: CalculationBasisAPI) => {
    return formatDateString(calculationBasis.from) + " - " + (calculationBasis.until === null ? "Offen" : formatDateString(calculationBasis.until)) +
        " | " +
        addCurrencySymbol(calculationBasis.amount) + " als " + formatAmountFlagString(calculationBasis.amountFlag);
}

export const formatAmountFlagString = (amountFlagString: string) => {
    const elementAmountFlag = getCalculationBasisAmountFlag().find(({ id }) => id === amountFlagString)
    return elementAmountFlag ? elementAmountFlag.name : "Ohne Betragskennzeichen"
}

export const formatDayJsMonthYearToFirstDayOfMonthString = (date: Dayjs) => {
    return `${date.year()}-${date.month() + 1}-01`
}

export const formatTimeStampUserString = (timestamp: string, user: UserAPI) => {
    return formatTimestampString(timestamp) + ` (${user.lastname}, ${user.firstname}) `
}