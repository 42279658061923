import { Button } from "@mui/material";
import { useState } from 'react';
import "../details.css"
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { } from "../../../redux/auth.reducer";
import PageLayout from "../../../shared/components/page-layout";
import { dateBeforeOrSameAsCurrentDate, endingDateSameOrAfterEffectiveDate } from "../../../helper/validation";
import TextFieldComponent from "../../../shared/components/text-field-component";
import { FileDownload } from "@mui/icons-material";
import { fetchCalculationRunCSVExport } from "../../../shared/requests/aid-request";
import { CalculationExportApiRequest } from "../../../models/aid";
import { saveAs } from 'file-saver';

function CalculationRunExporter() {
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [endingDate, setEndingDate] = useState<string>("0");
    const [requestPushed, setRequestPushed] = useState<boolean>(false);
    const errorStartRunValues = effectiveDate === "" || endingDate === "" || !dateBeforeOrSameAsCurrentDate(endingDate) || !endingDateSameOrAfterEffectiveDate(endingDate, effectiveDate)
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);

    const onCreateReportClicked = () => {
        setRequestPushed(true)
        if (!errorStartRunValues && user) {
            setRequestPushed(false)
            setEffectiveDate("")
            setEndingDate("")
            const data: CalculationExportApiRequest = {
                from: effectiveDate,
                until: endingDate
            }
            fetchCalculationRunCSVExport(user.institution.id, data).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                console.log(url);
                var blob = new Blob([response.data], {
                    type: "text/csv;charset=utf-8",
                });
                saveAs(blob, `berechnung-export.csv`);
            })
        }
    }


    return <PageLayout title={"Berechnungsdetails exportieren"}>
        <div className="single-view">
            <div className="flex flex-column single-view-person__data-container">
                <div className="flex flex-row single-view__form-row-width">
                    <TextFieldComponent
                        required={true}
                        id="effectiveDate"
                        label="Berechnungen Von"
                        type='date'
                        value={effectiveDate}
                        error={effectiveDate === "" && requestPushed}
                        helperText={
                            effectiveDate === "" && requestPushed
                                ? `Das Von Datum darf nicht leer sein!`
                                : ""
                        }
                        disabled={false}
                        setValueString={setEffectiveDate}
                    />
                    <TextFieldComponent
                        required={true}
                        id="endingDate"
                        label="Berechnungen Bis"
                        type='date'
                        value={endingDate}
                        error={(endingDate === "" || !dateBeforeOrSameAsCurrentDate(endingDate) || !endingDateSameOrAfterEffectiveDate(endingDate, effectiveDate)) && requestPushed}
                        helperText={
                            (endingDate === "" || !dateBeforeOrSameAsCurrentDate(endingDate) || !endingDateSameOrAfterEffectiveDate(endingDate, effectiveDate)) && requestPushed
                                ? `Das Bis Datum darf nicht leer sein und muss mindestens das Von Datum und maximal das Tagesdatum sein!`
                                : ""
                        }
                        disabled={false}
                        setValueString={setEndingDate}
                    />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <div className="single-view__empty-form-width"></div>
                    <Button startIcon={<FileDownload />} variant="contained" size="large" onClick={onCreateReportClicked}
                        sx={{
                            height: 50,
                            width: 300,
                            color: "#001D53",
                            background: "#D4E7FF",
                            ':hover': {
                                background: 'primary.main', // theme.palette.primary.main
                                color: 'white',
                            },
                        }}>Erstellen</Button>
                </div>
            </div>
        </div>
    </PageLayout>
}

export default CalculationRunExporter;