
import { Button } from "@mui/material";
import { Publish } from "@mui/icons-material";

export interface SubmitProps {
    importText: string;
    value: string;
    onClickImport: (value: string) => void;
    onClickDelete: () => void;
}

function SubmitButtonImport({
    importText,
    value,
    onClickImport,
    onClickDelete
}: SubmitProps) {
    return (
        <>
            <Button variant="contained" onClick={() => onClickImport(value)}>
                <Publish sx={{ mr: 1 }}></Publish>
                {importText}
            </Button>
            <Button variant="contained" onClick={onClickDelete}>
                Abbrechen
            </Button>
        </>
    );
}

export default SubmitButtonImport;
