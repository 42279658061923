import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import "./dialog.css";
import { Theme, useTheme } from "@mui/material";
import ErrorDisplay from "../../error-display";
import { useState } from "react";
import SubmitButtonsDialogHistory from "../submit-buttons/submit-button-dialog-history";
import { InvalidAPIRequest } from "../../../../models/person";
import { Female, Male, Transgender } from "@mui/icons-material";
import { formatDateString } from "../../../../helper/formatter";

export interface DeleteProps {
    showDialog: boolean;
    dialogDatatypeName: string;
    dataTypeName: string;
    personFirstName: string | undefined;
    personLastName: string | undefined;
    personGender: string | undefined;
    personDateOfBirth: string | undefined | null
    onClickSubmit: (value: InvalidAPIRequest | null) => void;
    onClickCancel: () => void;
}

const InvalidDialog = ({
    showDialog,
    onClickSubmit,
    dialogDatatypeName,
    dataTypeName,
    personFirstName,
    personLastName,
    personGender,
    personDateOfBirth,
    onClickCancel,
}: DeleteProps) => {
    const today = new Date();
    const theme: Theme = useTheme();
    const [effectiveDate, setEffectiveDate] = useState<string>(today.getFullYear() + '-' + ((today.getMonth() + 1 < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + today.getDate());
    const [calculationBasisInvalid, setCalculationBasisInvalid] = useState<boolean>(false);
    return (
        <Dialog open={showDialog}>
            <DialogTitle
                sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
            >
                Beenden {dialogDatatypeName}
            </DialogTitle>
            <DialogContent sx={{ marginTop: "1rem" }} className="DialogContent">
                <ErrorDisplay>
                    <DialogContentText>
                        <div className="dialog-content-text">
                            <p>Bitte geben Sie das Datum an, zu dem {dataTypeName} </p>
                            <p>({personGender === "GENDER_MALE" ? <Male></Male> : personGender === "GENDER_FEMALE" ? <Female></Female> : <Transgender></Transgender>} {personLastName}, {personFirstName} {personDateOfBirth ? "," + formatDateString(personDateOfBirth) : ""}) beendet werden soll.</p>
                            <div className="dialog-text-fields">
                                <TextField
                                    id="effectiveDate"
                                    label="Wirksamkeitsdatum"
                                    type='date'
                                    value={effectiveDate}
                                    className="single-view-employee__text-field"
                                    variant="outlined"
                                    error={effectiveDate === ""}
                                    helperText={
                                        effectiveDate === ""
                                            ? "Das Wirksamkeitsdatum darf nicht leer sein!"
                                            : ""
                                    }
                                    onChange={(event) => {
                                        setEffectiveDate(event.target.value);
                                    }}
                                />
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={calculationBasisInvalid} onChange={(event) => setCalculationBasisInvalid(event.target.checked)} />} label="Berechnungsgrundlagen zum o.g. Datum ebenfalls beenden?" />
                                </FormGroup>
                            </div>
                        </div>
                    </DialogContentText>
                </ErrorDisplay>
            </DialogContent>
            <DialogActions>
                <SubmitButtonsDialogHistory
                    submitText="SPEICHERN"
                    cancelText="ABBRECHEN"
                    onClickSubmit={onClickSubmit}
                    onClickCancel={onClickCancel}
                    value={{
                        effectiveDate: effectiveDate,
                        calculationBasisInvalid: calculationBasisInvalid,
                        otherPersonInvalid: false
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default InvalidDialog;
