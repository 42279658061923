export interface Person {
    id: string;
    historyNumber: string;
    effectiveDate: string;
    lastname: string;
    birthName: string | null;
    firstname: string;
    placeOfBirth: string;
    countryOfBirth: string | null;
    dateOfBirth: string | null;
    gender: string;
    namePrefix: string | null;
    title: string | null;
    nationality: string;
    pensionInsuranceNumber: string | null;
}

export type PersonAPI = {
    id: string;
    lastname: string;
    birthName: string | null;
    firstname: string;
    placeOfBirth: string;
    countryOfBirth: string | null;
    dateOfBirth: string | null;
    gender: string;
    namePrefix: string | null;
    title: string | null;
    nationality: string;
    pensionInsuranceNumber: string | null;
};

export type PersonAPIRequest = {
    lastname: string;
    birthName: string | null;
    firstname: string;
    placeOfBirth: string;
    countryOfBirth: string | null;
    dateOfBirth: string | null;
    gender: string;
    namePrefix: string | null;
    title: string | null;
    nationality: string | null;
    pensionInsuranceNumber: string | null;
};

export type InvalidAPIRequest = {
    effectiveDate: string;
    calculationBasisInvalid: boolean;
    otherPersonInvalid: boolean;
}

export type GenderType = {
    id: string;
    name: string;
}


export const getGenderType = () => {
    return [
        {
            id: "GENDER_MALE",
            name: "männlich",
        },
        {
            id: "GENDER_FEMALE",
            name: "weiblich",
        },
        {
            id: "GENDER_OTHER",
            name: "divers",
        },
    ]
}

export type UserSort = Pick<
    PersonAPI,
    "lastname" | "birthName" | "firstname" | "placeOfBirth"
>;

export type PersonShortKey = keyof Person;
