import {
    Autocomplete,
    TextField,
    styled,
    Tooltip,
    FormControl,
} from "@mui/material";
import { InstitutionSelect } from "../../models/institution";
import { formatInstitutionTypeString } from "../../helper/formatter";

type SelectProps = {
    selectData: Array<InstitutionSelect>;
    className: string;
    label: string;
    value: InstitutionSelect | null;
    onChange: (value: InstitutionSelect | null) => void;
    disabled: boolean;
    error: boolean;
    helperText?: string;
    notRequired?: boolean;
    setValueChanged?: (val: boolean) => void;
    tooltip: string;
    noTypeGroupName: string;
};

const GroupHeader = styled('div')(() => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "#2D3335",
    backgroundColor: "#D4E7FF",
}));

const GroupItems = styled('ul')({
    padding: 0,
    color: "#2D3335",
});

const AutoCompleteInstitutionComponent = ({
    selectData,
    className,
    tooltip,
    label,
    value,
    onChange,
    disabled,
    notRequired,
    setValueChanged,
    noTypeGroupName
}: SelectProps) => {
    return (
        <FormControl className={className}>
            {(!selectData.length && (
                <Tooltip title={tooltip}>
                    <Autocomplete
                        options={[]}
                        sx={{
                            width: "100%",
                            backgroundColor: "#EEEEEE",
                        }}
                        renderInput={(params) => <TextField {...params} label={label} />}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                        disabled
                    />
                </Tooltip>
            )) || (
                    <Autocomplete
                        options={selectData.sort((a, b) => {
                            if (a.type.toLowerCase() < b.type.toLowerCase()) { return -1; }
                            if (a.type.toLowerCase() > b.type.toLowerCase()) { return 1; }
                            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                            return 0;
                        })}
                        value={value}
                        groupBy={(option) => option.type}
                        getOptionLabel={(option) => option.name}
                        sx={{
                            width: "100%",
                            backgroundColor: "#EEEEEE",
                        }}
                        renderInput={(params) => <TextField {...params} label={label} />}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{formatInstitutionTypeString(params.group, noTypeGroupName)}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                        disabled={disabled}
                        onChange={(event, val) => {
                            if (setValueChanged) {
                                setValueChanged(true);
                            }
                            onChange(val);
                        }}
                    />
                )}
        </FormControl>
    );
};

export default AutoCompleteInstitutionComponent;
