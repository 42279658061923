import { Button } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import "../details.css"
import { useNavigate } from "react-router-dom";
import CrudTable, { ColumnDefinitionType } from "../../../shared/components/crud-table/crud-table";
import { ProcessAPI, ProcessSort, isAidUser } from "../../../models/user";
import { cancelProcess, fetchAllProcesses, importProcess, saveProcess } from "../../../shared/requests/user.requests";
import { Sort } from "../../../hooks/useSort.hook";
import { SortingDirection } from "../../../enums/sort-by";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { } from "../../../redux/auth.reducer";
import PageLayout from "../../../shared/components/page-layout";
import AccordionComponent from "../../../shared/components/accordion-component";
import { AssistWalker, Person } from "@mui/icons-material";
import ConfirmCancelDialog from "../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog";
import ErrorDialog from "../../../shared/components/standard-actions/dialogs/error-dialog";
import { clearHistory } from "../../../models/history";
import { formatTimestampString } from "../../../helper/formatter";
import { hasGetPersonDataPermission } from "../../../helper/validation";

function Home() {
    const [institutionOpenProcesses, setInstitutionOpenProcesses] = useState<ProcessAPI[]>([]);
    const [institutionNotImportedProcesses, setInstitutionNotImportedProcesses] = useState<ProcessAPI[]>([]);
    const [showSaveOpenProcessDialog, setShowSaveOpenProcessDialog] = useState<boolean>(false);
    const [showImportProcessDialog, setShowImportProcessDialog] = useState<boolean>(false);
    const [showCancelOpenProcessDialog, setShowCancelOpenProcessDialog] = useState<boolean>(false);
    const [selectedOpenProcess, setSelectedOpenProcess] = useState<ProcessAPI | null>(null);
    const [externalModifiedOpenProcesses, setExternalModifiedOpenProcesses] = useState<boolean>(false);
    const [externalModifiedNotImportedProcesses, setExternalModifiedNotImportedProcesses] = useState<boolean>(false);
    const [showOpenProcessErrorDialog, setShowOpenProcessErrorDialog] = useState<boolean>(false);
    const isInitRender = useRef(true);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isInitRender.current && user) {
            clearHistory();
            fetchAllProcesses().then((response) => {
                const openProcesses: ProcessAPI[] = []
                const notImportedProcesses: ProcessAPI[] = []
                response.data.forEach((element) => {
                    if (element.user.id === user.id && element.processStatus === "E_PROCESS_STATUS_OPEN") {
                        openProcesses.push(element);
                    }
                    if (element.processStatus === "E_PROCESS_STATUS_NOT_IMPORTED") {
                        notImportedProcesses.push(element);
                    }
                });
                setInstitutionOpenProcesses(openProcesses);
                setInstitutionNotImportedProcesses(notImportedProcesses);
            });
            isInitRender.current = false;
        }
    });

    const columns: ColumnDefinitionType<ProcessAPI, keyof ProcessAPI>[] = [
        {
            key: "startString",
            header: "Start Zeitstempel",
        },
        {
            key: "transactionText",
            header: "Bearbeitete Daten",
        },
    ]
    const columnsImported: ColumnDefinitionType<ProcessAPI, keyof ProcessAPI>[] = [
        {
            key: "institutionString",
            header: "Institution",
        },
        {
            key: "userString",
            header: "Benutzer",
        },
        {
            key: "startString",
            header: "Start Zeitstempel",
        },
        {
            key: "endString",
            header: "Ende Zeitstempel",
        },
        {
            key: "transactionText",
            header: "Bearbeitete Daten",
        },
    ]

    const [sortBy] = useState<Sort<ProcessSort>>({
        key: "institutionString",
        direction: SortingDirection.Descending,
    });

    const navigateToPersonInNeedOfCare = () => {
        navigate(`/person-in-need-of-care`);
    }
    const navigateToCaringPerson = () => {
        navigate(`/caring-person`);
    }

    const openEditElementOfProccessOnClick = (element: ProcessAPI) => {
        if (element.transactions.length > 0) {
            if (element.transactions[0].objectType === "Bedürftige/r") {
                navigate(`/person-in-need-of-care/edit/` + element.transactions[0].id + "/" + element.transactions[0].historyNumber + "/" + element.id);
            } else if (element.transactions[0].objectType === "Pflegeperson") {
                navigate(`/caring-person/edit/` + element.transactions[0].id + "/" + element.transactions[0].historyNumber + "/" + element.id);
            } else if (element.transactions[0].objectType === "Berechnungsgrundlagen") {
                navigate(`/calculation-basis/edit/` + element.transactions[0].id + "/" + element.transactions[0].historyNumber + "/" + element.id);
            }
        }
    }



    const onProcessCancel = () => {
        setShowCancelOpenProcessDialog(false);
        setShowSaveOpenProcessDialog(false)
        setShowOpenProcessErrorDialog(false)
    }

    const saveProccessOnClick = (element: ProcessAPI) => {
        if (user && element.user.id === user?.id) {
            setSelectedOpenProcess(element);
            setShowSaveOpenProcessDialog(true);
        } else {
            setShowOpenProcessErrorDialog(true)
        }
    }

    const onSaveProcessSubmit = () => {
        if (selectedOpenProcess != null) {
            saveProcess(selectedOpenProcess.id).then(() => {
                const newInstitutionOpenProcesses: ProcessAPI[] = [];
                institutionOpenProcesses.forEach((process) => {
                    if (process.id !== selectedOpenProcess.id) {
                        newInstitutionOpenProcesses.push(process)
                    }
                })
                setInstitutionOpenProcesses(newInstitutionOpenProcesses)
                setExternalModifiedOpenProcesses(true)
                setShowSaveOpenProcessDialog(false);
            })
        }
    }

    const importProccessOnClick = (element: ProcessAPI) => {
        setSelectedOpenProcess(element);
        setShowImportProcessDialog(true);
    }

    const onImportProcessSubmit = () => {
        if (selectedOpenProcess != null) {
            importProcess(selectedOpenProcess.id).then(() => {
                const newInstitutionProcesses: ProcessAPI[] = [];
                institutionNotImportedProcesses.forEach((process) => {
                    if (process.id !== selectedOpenProcess.id) {
                        newInstitutionProcesses.push(process)
                    }
                })
                setInstitutionNotImportedProcesses(newInstitutionProcesses)
                setExternalModifiedNotImportedProcesses(true)
                setShowImportProcessDialog(false);
            })
        }
    }

    const cancelProccessOnClick = (element: ProcessAPI) => {
        if (user && (element.user.id === user?.id || element.processStatus === "E_PROCESS_STATUS_NOT_IMPORTED")) {
            setSelectedOpenProcess(element);
            setShowCancelOpenProcessDialog(true);
        } else {
            setShowOpenProcessErrorDialog(true)
        }
    }

    const onCancelProcessSubmit = () => {
        if (selectedOpenProcess != null) {
            cancelProcess(selectedOpenProcess.id).then(() => {
                const openProcesses: ProcessAPI[] = []
                const notImportedProcesses: ProcessAPI[] = []
                institutionOpenProcesses.forEach((process) => {
                    if (process.id !== selectedOpenProcess.id && process.processStatus === "E_PROCESS_STATUS_OPEN") {
                        openProcesses.push(process);
                    }
                    if (process.id !== selectedOpenProcess.id && process.processStatus === "E_PROCESS_STATUS_NOT_IMPORTED") {
                        notImportedProcesses.push(process);
                    }
                })
                setInstitutionOpenProcesses(openProcesses);
                setInstitutionNotImportedProcesses(notImportedProcesses);
                setExternalModifiedOpenProcesses(true)
                setShowCancelOpenProcessDialog(false);
            })
        }
    }

    return <PageLayout title={"Home"}>
        <ConfirmCancelDialog
            showDialog={showSaveOpenProcessDialog}
            dialogTitle="Speichern eines Vorgangs"
            dialogInfo="Soll der ausgewählte Vorgang wirklich abgespeichert werden?"
            onClickCancel={onProcessCancel}
            onClickSubmit={onSaveProcessSubmit}
        />
        <ConfirmCancelDialog
            showDialog={showImportProcessDialog}
            dialogTitle="Importieren eines Vorgangs"
            dialogInfo="Soll der ausgewählte Vorgang wirklich importiert werden?"
            onClickCancel={onProcessCancel}
            onClickSubmit={onImportProcessSubmit}
        />
        <ConfirmCancelDialog
            showDialog={showCancelOpenProcessDialog}
            dialogTitle="Verwerfen eines Vorgangs"
            dialogInfo="Soll der ausgewählte Vorgang wirklich verworfen werden?"
            onClickCancel={onProcessCancel}
            onClickSubmit={onCancelProcessSubmit}
        />
        <ErrorDialog
            showDialog={showOpenProcessErrorDialog}
            dialogInfo="Der Vorgang kann nur von dem Benutzer, der diesen angelegt hat bearbeitet werden!"
            onClickSubmit={onProcessCancel}
        />
        {institutionOpenProcesses.length > 0 && <div className='single-view-accordion'>
            <AccordionComponent
                label="Offene Vorgänge"
                defaultExpanded={true}
                content={<CrudTable
                    tableData={institutionOpenProcesses}
                    externalModified={externalModifiedOpenProcesses}
                    setExternalModified={setExternalModifiedOpenProcesses}
                    columns={columns}
                    sortBy={sortBy}
                    fetchElements={fetchAllProcesses}
                    callOnEditClick={openEditElementOfProccessOnClick}
                    callOnSaveClick={saveProccessOnClick}
                    callOnRestoreClick={cancelProccessOnClick}
                    dataTypeName="Vorgang"
                    dataFormatter={(element: ProcessAPI) => {
                        element.userString = element.user.lastname + ", " + element.user.firstname
                        element.statusString = element.processStatus === "E_PROCESS_STATUS_OPEN" ? "Offen" : element.processStatus === "E_PROCESS_STATUS_NOT_IMPORTED" ? "Nicht Importiert" : "Beendet"
                        element.startString = formatTimestampString(element.startTimestamp)
                        if (element.endTimestamp !== null) {
                            element.endString = formatTimestampString(element.endTimestamp)
                        } else {
                            element.endString = ""
                        }
                        element.transactionText = element.transactions.map((transaction) => {
                            return <div className="process-transaction">{transaction.objectType + ' - ' + transaction.name} </div>;
                        })
                        element.institutionString = element.user.institution.name;
                        return element;
                    }}
                    filterData={(element: ProcessAPI) => {
                        if (user != null && element.user.id === user.id && element.processStatus === "E_PROCESS_STATUS_OPEN") {
                            return true;
                        }
                        return false;
                    }}
                />}
            />
        </div>
        }
        {(institutionNotImportedProcesses.length > 0 && user) && <div className='single-view-accordion'>
            <AccordionComponent
                label={isAidUser(user) ? "Importierbare Vorgänge" : "Vogänge, die auf Import warten"}
                defaultExpanded={true}
                content={<CrudTable
                    tableData={institutionNotImportedProcesses}
                    externalModified={externalModifiedNotImportedProcesses}
                    setExternalModified={setExternalModifiedNotImportedProcesses}
                    columns={columnsImported}
                    sortBy={sortBy}
                    fetchElements={fetchAllProcesses}
                    callOnSaveClick={isAidUser(user) ? importProccessOnClick : undefined}
                    callOnRestoreClick={isAidUser(user) ? cancelProccessOnClick : undefined}
                    dataTypeName="Vorgang"
                    dataFormatter={(element: ProcessAPI) => {
                        element.userString = element.user.lastname + ", " + element.user.firstname
                        element.statusString = element.processStatus === "E_PROCESS_STATUS_OPEN" ? "Offen" : element.processStatus === "E_PROCESS_STATUS_NOT_IMPORTED" ? "Nicht Importiert" : "Beendet"
                        element.startString = formatTimestampString(element.startTimestamp)
                        if (element.endTimestamp !== null) {
                            element.endString = formatTimestampString(element.endTimestamp)
                        } else {
                            element.endString = ""
                        }
                        element.transactionText = element.transactions.map((transaction) => {
                            return <div className="process-transaction">{transaction.objectType + ' - ' + transaction.name} </div>;
                        })
                        element.institutionString = element.user.institution.name;
                        return element;
                    }}
                    filterData={(element: ProcessAPI) => {
                        if (user != null && element.processStatus === "E_PROCESS_STATUS_NOT_IMPORTED") {
                            return true;
                        }
                        return false;
                    }}
                />}
            />
        </div>}
        {user && hasGetPersonDataPermission(user.permissions, null) && <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="auto"
            sx={{ '& button': { mr: 10, mt: 2 } }}>
            <Button startIcon={<AssistWalker />} variant="contained" size="large" onClick={navigateToPersonInNeedOfCare}
                sx={{
                    height: 150,
                    width: 150,
                    color: "#001D53",
                    background: "#D4E7FF",
                    ':hover': {
                        background: 'primary.main', // theme.palette.primary.main
                        color: 'white',
                    },
                }}>Bedürftige/n suchen</Button>
            <Button startIcon={<Person />} variant="contained" size="large" onClick={navigateToCaringPerson}
                sx={{
                    height: 150,
                    width: 150,
                    color: "#001D53",
                    background: "#D4E7FF",
                    ':hover': {
                        background: 'primary.main', // theme.palette.primary.main
                        color: 'white',
                    },
                }}>Pflegeperson suchen</Button>
        </Box>
        }
    </PageLayout>
}

export default Home;