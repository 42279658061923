import { useEffect, useRef, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import { InstitutionAPI, InstitutionSort } from "../../../../models/institution";
import CrudTable, {
    ColumnDefinitionType,
} from "../../../../shared/components/crud-table/crud-table";
import {
    changeActiveOfInstitution,
    deleteInstitution,
    fetchInstitutions,
    importAids,
    importInsurances,
    importPensionInstitutions,
} from "../../../../shared/requests/institution.requests";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import { useNavigate } from 'react-router-dom';
import ConfirmCancelDialog from '../../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog';
import { clearHistory } from '../../../../models/history';
import { formatAddressString, formatInstitutionTypeString, formatSuccessorString } from '../../../../helper/formatter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { } from "../../../../redux/auth.reducer";
import { Box, Button } from '@mui/material';
import { Healing, HealthAndSafety, Inventory } from '@mui/icons-material';
import ImportDataDialog from "../../../../shared/components/standard-actions/dialogs/file-select-dialog";

function ListInstitutions() {
    const [sortBy] = useState<Sort<InstitutionSort>>({
        key: "id",
        direction: SortingDirection.Descending,
    });

    const [institutions, setInstitutions] = useState<InstitutionAPI[]>([]);
    const [externalModified, setExternalModified] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showInstitutionInvalidDialog, setShowInstitutionInvalidDialog] = useState<boolean>(false);
    const [selectedInstitution, setSelectedInstitution] = useState<InstitutionAPI | null>(null);
    const [entityName, setEntityName] = useState<string>("");
    const [clickedId, setClickedId] = useState<string>("");
    const [institutionDataType, setInstitutionDataType] = useState<string>("");
    const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const isInitRender = useRef(true);
    const navigate = useNavigate();

    const onAddClick = () => {
        navigate('add');
    }
    const onEditClick = (entity: InstitutionAPI) => {
        navigate(`edit/${entity.id}`);
    }

    const onDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const onDeleteSubmit = () => {
        deleteInstitution(clickedId ?? "").then(() => {
            setShowDeleteDialog(false)
            setExternalModified(true)
        });
    }

    const onInvalidClick = (entity: InstitutionAPI) => {
        setEntityName(entity.name)
        setClickedId(entity.id)
        setSelectedInstitution(entity)
        setShowInstitutionInvalidDialog(true)
    }

    const onInvalidCancel = () => {
        setShowInstitutionInvalidDialog(false)
    }

    const onInvalidSubmit = () => {
        changeActiveOfInstitution(clickedId ?? "").then(() => {
            setShowInstitutionInvalidDialog(false)
            setExternalModified(true)
        });
    }

    const onUserClick = (entity: InstitutionAPI) => {
        navigate(`/user/add/aid/${entity.id}`)
    }

    const showUserIconValidation = (entity: InstitutionAPI) => {
        return entity.type === "INSTITUTION_TYPE_SYSTEM" || entity.type === "INSTITUTION_TYPE_AID" || entity.type === "INSTITUTION_TYPE_PRIVATE_HEALTH_INSURANCE" || entity.type === "INSTITUTION_TYPE_STATUTORY_HEALTH_INSURANCE";
    }


    useEffect(() => {
        clearHistory();
        if (isInitRender.current) {
            fetchInstitutions().then((response) => {
                setInstitutions(response.data);
            });
            isInitRender.current = false;
        }
    });

    const importAidsShow = () => {
        setShowImportDialog(true);
        setInstitutionDataType("Beihilfestellen")
    }

    const importInsurancesShow = () => {
        setShowImportDialog(true);
        setInstitutionDataType("Versicherungen")
    }

    const importPensionInstitutionsShow = () => {
        setShowImportDialog(true);
        setInstitutionDataType("Versorgungseinrichtungen")
    }

    const onImportSubmit = (value: string) => {
        if (institutionDataType === "Beihilfestellen") {
            importAids(value).then(() => {
                setExternalModified(true)
                setShowImportDialog(false)
                setInstitutionDataType("")
            })
        } else if (institutionDataType === "Versicherungen") {
            importInsurances(value).then(() => {
                setExternalModified(true)
                setShowImportDialog(false)
                setInstitutionDataType("")
            })
        } else if (institutionDataType === "Versorgungseinrichtungen") {
            importPensionInstitutions(value).then(() => {
                setExternalModified(true)
                setShowImportDialog(false)
                setInstitutionDataType("")
            })
        }
    }

    const onImportCancel = () => {
        setShowImportDialog(false)
        setInstitutionDataType("")
    }


    const columns: ColumnDefinitionType<InstitutionAPI, keyof InstitutionAPI>[] = [
        {
            key: "id",
            header: "ID",
        },

        {
            key: "name",
            header: "Name",
        },
        {
            key: "typeString",
            header: "Institutions-Typ",
        },
        {
            key: "successorID",
            header: "Nachfolger",
        },
        {
            key: "addressString",
            header: "Anschrift",
        }
    ];
    return (
        <PageLayout title={"Übersicht Institutionen"}>
            <ConfirmCancelDialog
                showDialog={showDeleteDialog}
                dialogTitle={"Löschen einer Institution"}
                dialogInfo={`Soll die Institution "${entityName}" wirklich gelöscht werden?`}
                onClickCancel={onDeleteCancel}
                onClickSubmit={onDeleteSubmit}
            />
            <ConfirmCancelDialog
                showDialog={showInstitutionInvalidDialog && selectedInstitution !== null}
                dialogTitle={`${selectedInstitution?.active ? "Ungültig" : "Gültig"} setzen einer Institution`}
                dialogInfo={`Soll die Institution  "${entityName}" wirklich ${selectedInstitution?.active ? "Ungültig" : "Gültig"} gesetzt werden?`}
                onClickCancel={onInvalidCancel}
                onClickSubmit={onInvalidSubmit}
            />
            <ImportDataDialog
                dialogTitle={`Importieren von ${institutionDataType}`}
                dialogInfo={`Bitte wähle unten die Datei, aus welcher die ${institutionDataType} importiert werden sollen:`}
                onClickDelete={onImportCancel}
                onClickSubmit={onImportSubmit}
                showDialog={showImportDialog}
            />
            <CrudTable
                tableData={institutions}
                callOnAddClick={onAddClick}
                callOnEditClick={onEditClick}
                callOnInactiveClick={onInvalidClick}
                callOnUserClick={onUserClick}
                onlyChowUserIconWhen={showUserIconValidation}
                externalModified={externalModified}
                setExternalModified={setExternalModified}
                columns={columns}
                sortBy={sortBy}
                fetchElements={fetchInstitutions}
                dataTypeName="Institution"
                dataFormatter={(data: InstitutionAPI) => {
                    data.successorID = formatSuccessorString(data.successor)
                    data.addressString = formatAddressString(data.address)
                    data.typeString = formatInstitutionTypeString(data.type, "Ohne Institutionstyp")
                    return data;
                }}
            />
            {user && user.permissions.includes("EDIT_ALL_INSTITUTIONS")}{
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="auto"
                    sx={{ '& button': { mr: 10, mt: 2 } }}>
                    <Button startIcon={<HealthAndSafety />} variant="contained" size="large" onClick={importAidsShow}
                        sx={{
                            height: 50,
                            width: 320,
                            color: "#001D53",
                            background: "#D4E7FF",
                            ':hover': {
                                background: 'primary.main', // theme.palette.primary.main
                                color: 'white',
                            },
                        }}>Beihilfestellen importieren</Button>
                    <Button startIcon={<Healing />} variant="contained" size="large" onClick={importInsurancesShow}
                        sx={{
                            height: 50,
                            width: 320,
                            color: "#001D53",
                            background: "#D4E7FF",
                            ':hover': {
                                background: 'primary.main', // theme.palette.primary.main
                                color: 'white',
                            },
                        }}>Versicherungen importieren</Button>
                    <Button startIcon={<Inventory />} variant="contained" size="large" onClick={importPensionInstitutionsShow}
                        sx={{
                            height: 50,
                            width: 320,
                            color: "#001D53",
                            background: "#D4E7FF",
                            ':hover': {
                                background: 'primary.main', // theme.palette.primary.main
                                color: 'white',
                            },
                        }}>Versorgungseinrichtungen importieren</Button>
                </Box>
            }
        </PageLayout>
    );
}

export default ListInstitutions;
