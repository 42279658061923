import { useEffect } from 'react';
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    InstitutionAPIRequest,
    InstitutionPayModelAPI,
    InstitutionTypes,
    getInstitutionTypesWithoutSystem,
} from "../../../../models/institution";
import { userInformationRequest } from "../../../../redux/auth.reducer";
import SubmitButtons from "../../../../shared/components/standard-actions/submit-buttons/submit-button";
import {
    createInstitution,
    fetchInstitution,
    updateInstitution,
    fetchInstitutions
} from "../../../../shared/requests/institution.requests";
import SelectComponent from "../../../../shared/components/select-component";
import { InstitutionSelect } from '../../../../models/institution';
import { AddressAPIRequest, CountrySelect, getCountries } from '../../../../models/address';
import AddressForm from '../../../../shared/components/address-form/address-form';
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { } from "../../../../redux/auth.reducer";
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { isValidPostalcode, onlyDigits } from '../../../../helper/validation';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { Add, Business, Delete, MonetizationOn } from '@mui/icons-material';
import { clearHistory } from '../../../../models/history';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/de';
import dayjs, { Dayjs } from 'dayjs';
import AutoCompleteInstitutionComponent from '../../../../shared/components/auto-complete-component-institutions';
import { Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Tooltip } from '@mui/material';

function Institution() {
    const [institutionTypes, setInstitutionTypes] = useState<InstitutionTypes[]>([]);
    const [institutions, setInstitutions] = useState<InstitutionSelect[]>([]);

    const [name, setName] = useState<string>("");
    const [institutionType, setInstitutionType] = useState<InstitutionTypes | null>(null);
    const [successsor, setSuccesssor] = useState<InstitutionSelect | null>(null);
    const [companyNumber, setCompanyNumber] = useState<string>("");
    const [earliestCalculationYear, setEarliestCalculationYear] = useState<Dayjs | null>(null);
    const [latestRunDate, setLatestRunDate] = useState<string | null>(null);
    //Address Data
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string | null>("");
    const [country, setCountry] = useState<CountrySelect | null>(null);
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [poBox, setPOBox] = useState<string>("");
    const [poBoxPostalCode, setPOBoxPostalCode] = useState<string>("");
    const [addressSupplement, setAddressSupplement] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    //PayModel Data
    const [basicCosts, setBasicCosts] = useState<number | null>(null);
    const [amountCases, setAmountCases] = useState<number>(0);
    const [amountToPayForCalculation, setAmountToPayForCalculation] = useState<number>(0);
    const [payModel, setPayModel] = useState<InstitutionPayModelAPI[]>([]);

    const [savePushed, setSavePushed] = useState<boolean>(false);
    const errorInstitution: boolean = name === "" || institutionType?.name === "" || (companyNumber === "" || !onlyDigits(companyNumber) || companyNumber.length !== 8);
    const errorAddress: boolean = postalCode === "" || location === "" || country === null || !isValidPostalcode(postalCode, country) || country === null;
    const errorPayModel: boolean = institutionType?.id === "INSTITUTION_TYPE_AID" && (payModel === null || payModel.length === 0)
    const errorInput: boolean = errorInstitution || errorAddress || errorPayModel;
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);



    const goBackToOverview = () => {
        dispatch(userInformationRequest()).then(() => {
            if (user?.permissions.includes("institution:edit")) {
                navigate(`/institution`);
            } else {
                navigate(`/home`);
            }
        })

    }

    useEffect(() => {
        clearHistory();
        fetchInstitutions().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            let emptySuccessor: InstitutionSelect = {
                id: String(""),
                name: "Kein Nachfolger",
                realValue: -1,
                type: ""
            }
            setSuccesssor(emptySuccessor);
            institutionsSelect.push(emptySuccessor)
            institutions.forEach((institution) => {
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setInstitutions(institutionsSelect.sort((a, b) => {
                if (a.type.toLowerCase() < b.type.toLowerCase()) { return -1; }
                if (a.type.toLowerCase() > b.type.toLowerCase()) { return 1; }
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }));
        })
        setInstitutionTypes(getInstitutionTypesWithoutSystem());
        if (id) {
            fetchInstitution(id).then((response) => {
                setName(response.data.name);
                setCompanyNumber(response.data.companyNumber)
                setLatestRunDate(response.data.latestRunDate)
                if (response.data.earliestCalculationYear) {
                    setEarliestCalculationYear(dayjs().set('year', response.data.earliestCalculationYear))
                }
                const element = getInstitutionTypesWithoutSystem().find((element) => { return response.data.type === element.id; });
                if (element) {
                    setInstitutionType(element);
                }
                if (response.data.successor != null) {
                    setSuccesssor({
                        id: response.data.successor!.id,
                        name: response.data.successor!.name,
                        realValue: +response.data.successor!.id,
                        type: response.data.successor!.type
                    })
                }
                if (response.data.address != null) {
                    setStreet(response.data.address.street);
                    setHouseNumber(response.data.address.houseNumber);
                    const elementCountry = getCountries().find(element => element.id === response.data.address?.country) || null
                    if (elementCountry) {
                        setCountry(elementCountry);
                    }
                    setPostalCode(response.data.address.postalCode);
                    setLocation(response.data.address.location);
                    setPOBox(response.data.address.poBox);
                    setPOBoxPostalCode(response.data.address.poBoxPostalCode);
                    setAddressSupplement(response.data.address.addressSupplement);
                    setPhoneNumber(response.data.address.phoneNumber);
                    setFax(response.data.address.fax);
                    setEmail(response.data.address.email);
                }
                setBasicCosts(response.data.basicCosts)
                setPayModel(response.data.payModel ? response.data.payModel.sort((a, b) => a.fromAmountCalculations > b.fromAmountCalculations ? 1 : -1) : []);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserTitle = (): string => {
        const createUserTitle = "Institution erstellen";
        const editUserTitle = "Institution bearbeiten";
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createText = "Erstellen";
        const editText = "Speichern";
        if (id) {
            return editText;
        }
        return createText;
    };

    const handleInputChangeInstitutionType = (value: string) => {
        setInstitutionType(institutionTypes.find(({ name }) => name === value) || null);
    };

    const handleInputChangeSuccessorSel = (value: InstitutionSelect | null) => {
        setSuccesssor(value);
    };

    const filterOptions = (value: InstitutionSelect[]) => {
        return value.filter((insSelect) => insSelect.id !== id && (institutionType === null || insSelect.type === institutionType.id || insSelect.type === ""))
    }



    const save = () => {
        var today = new Date();
        const effectiveDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const coun = country ? country.id : "";
        const address: AddressAPIRequest = {
            effectiveDate,
            houseNumber,
            country: coun,
            postalCode,
            poBox,
            poBoxPostalCode,
            addressSupplement,
            location,
            phoneNumber,
            fax,
            email,
            street,
        };
        const type = institutionType ? institutionType.id : ""
        const successorID = (successsor && successsor.id !== "") ? successsor.realValue : null;
        const calcYear = earliestCalculationYear ? earliestCalculationYear.year() : null;
        const payMod = payModel.length > 0 ? payModel : null
        const institution: InstitutionAPIRequest = {
            id: id ? id : "",
            type,
            name,
            successorID,
            companyNumber,
            address,
            earliestCalculationYear: calcYear,
            payModel: payMod,
            basicCosts
        };
        setSavePushed(true);
        if (!errorInput) {
            if (id) {
                updateInstitution(institution).then(goBackToOverview).catch(console.error);
            } else {
                createInstitution(institution).then(goBackToOverview).catch(console.error);
            }
        }
    };

    const actions = (
        <SubmitButtons
            submitText={getSavingButtonText()}
            cancelText="Abbrechen"
            onClickSubmit={save}
            onClickCancel={goBackToOverview}
        />
    );

    const addAndSortPriceModel = () => {
        if (amountCases !== null && amountCases >= 0 && amountToPayForCalculation !== null && amountToPayForCalculation >= 0) {
            const priceModel: InstitutionPayModelAPI[] = payModel;
            const newEntry: InstitutionPayModelAPI = {
                amountPerCalculation: amountToPayForCalculation,
                fromAmountCalculations: amountCases
            }
            let notIn = true;
            priceModel.forEach((element) => {
                if (newEntry.fromAmountCalculations === element.fromAmountCalculations) {
                    notIn = false;
                    element.amountPerCalculation = amountToPayForCalculation;
                }
            })
            if (notIn) {
                priceModel.push(newEntry)
            }
            setPayModel(priceModel.sort((a, b) => a.fromAmountCalculations > b.fromAmountCalculations ? 1 : -1))
            setAmountCases(0);
            setAmountToPayForCalculation(0);
        } else {

        }
    }

    const deleteAndSortPriceModel = (value: InstitutionPayModelAPI) => {
        if (user != null && user.permissions.includes("institution:edit")) {
            const priceModel: InstitutionPayModelAPI[] = [];
            payModel.forEach((val) => {
                if (val !== value) {
                    priceModel.push(val)
                }
            })
            setPayModel(priceModel)
        }
    }

    return (
        <PageLayout title={getUserTitle()} actions={actions}>
            <div className="single-view">
                <div className='single-view-accordion'>
                    <AccordionComponent
                        defaultExpanded
                        label='Institutionsdaten'
                        error={errorInstitution}
                        validation
                        startIcon={<Business />}
                        content={
                            <div>
                                <div className="flex flex-row single-view__form-row-width">
                                    <TextFieldComponent
                                        required={true}
                                        id="name"
                                        label="Name der Institution"
                                        value={name}
                                        type='text'
                                        error={name === "" && savePushed}
                                        helperText={
                                            name === "" && savePushed
                                                ? "Der Name darf nicht leer sein!"
                                                : ""
                                        }
                                        disabled={false}
                                        setValueString={setName}
                                    />
                                    <TextFieldComponent
                                        required={true}
                                        id="companyNumber"
                                        label="Betriebsnummer"
                                        value={companyNumber}
                                        type='text'
                                        error={(companyNumber === "" || !onlyDigits(companyNumber) || companyNumber.length !== 8) && savePushed}
                                        helperText={
                                            (companyNumber === "" || !onlyDigits(companyNumber) || companyNumber.length !== 8) && savePushed
                                                ? "Die Betriebsnummer muss aus 8 Zahlen bestehen!"
                                                : ""
                                        }
                                        disabled={false}
                                        setValueString={setCompanyNumber}
                                    />
                                </div>
                                <div className="flex flex-row single-view__form-row-width">
                                    <SelectComponent
                                        selectData={institutionTypes}
                                        tooltip="Es wurden noch keine Institutions-Typen angelegt."
                                        className="single-view__text-field"
                                        label="Institutions-Typ"
                                        value={institutionType?.name || ""}
                                        onChange={handleInputChangeInstitutionType}
                                        disabled={id !== null && id !== undefined}
                                        error={savePushed && !institutionType}
                                    ></SelectComponent>
                                    <AutoCompleteInstitutionComponent
                                        noTypeGroupName='Keine Auwahl'
                                        selectData={filterOptions(institutions)}
                                        tooltip="Es wurden noch keine Institutionen angelegt."
                                        className="single-view__text-field"
                                        label="Nachfolger Institution"
                                        value={successsor}
                                        onChange={handleInputChangeSuccessorSel}
                                        disabled={false}
                                        error={false}
                                    />
                                </div>
                                {institutionType !== null && institutionType.id === "INSTITUTION_TYPE_AID" &&
                                    <div className="flex flex-row single-view__form-row-width">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="de"
                                            localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
                                        >
                                            <DatePicker

                                                disabled={latestRunDate !== null}
                                                value={earliestCalculationYear}
                                                label={'Erstes Berechnungsjahr'}
                                                views={['year']}
                                                onChange={(value) => {
                                                    setEarliestCalculationYear(value)
                                                }}
                                                sx={{
                                                    width: "100%",
                                                    backgroundColor: "#EEEEEE",
                                                    color: '#2D3335',
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <div className="single-view__empty-form-width"></div>
                                    </div>
                                }
                            </div>
                        }
                    />
                </div>

                <AddressForm
                    savePushed={savePushed}
                    disabled={false}
                    street={street}
                    setStreet={setStreet}
                    houseNumber={houseNumber}
                    setHouseNumber={setHouseNumber}
                    postalCode={postalCode}
                    setPostalCode={setPostalCode}
                    location={location}
                    setLocation={setLocation}
                    country={country}
                    setCountry={setCountry}
                    poBox={poBox}
                    setPOBox={setPOBox}
                    poBoxPostalCode={poBoxPostalCode}
                    setPOBoxPostalCode={setPOBoxPostalCode}
                    addressSupplement={addressSupplement}
                    setAddressSupplement={setAddressSupplement}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    fax={fax}
                    setFax={setFax}
                    email={email}
                    setEmail={setEmail}
                    useAddress={true}
                    formError={errorAddress}
                    validation
                />

                {institutionType !== null && institutionType.id === "INSTITUTION_TYPE_AID" &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            defaultExpanded={false}
                            label='Kostenmodell'
                            error={errorPayModel}
                            validation
                            startIcon={<MonetizationOn />}
                            content={
                                <div>
                                    <TextFieldComponent
                                        required={true}
                                        id="basicCosts"
                                        label="Grundkosten"
                                        value={basicCosts}
                                        error={basicCosts === null || basicCosts < 0}
                                        helperText={basicCosts === null || basicCosts < 0 ? "Die Grundkosten dürfen nicht negativ sein!" : ""}
                                        type='number'
                                        disabled={user == null || !user.permissions.includes("institution:edit")}
                                        setValueNumber={setBasicCosts}
                                    />
                                    <List
                                        sx={{
                                            width: '100%',
                                            height: "400px",
                                            bgcolor: 'background.paper',
                                            position: 'relative',
                                            overflow: 'auto',
                                            maxHeight: 500,
                                            '& ul': { padding: 0 },
                                        }}
                                        aria-label="av records"
                                    >
                                        {user != null && user.permissions.includes("institution:edit") &&
                                            <div className="flex flex-row single-view__form-row-width">
                                                <TextFieldComponent
                                                    required={false}
                                                    id="amountCalculations"
                                                    label="Mindestanzahl Fälle"
                                                    value={amountCases}
                                                    error={amountCases === null || amountCases < 0}
                                                    helperText={amountCases === null || amountCases < 0 ? "Die Mindestanzahl an Fällen muss mindestens 0 sein!" : ""}
                                                    type='number'
                                                    disabled={false}
                                                    setValueNumber={setAmountCases}
                                                />
                                                <TextFieldComponent
                                                    required={false}
                                                    id="amountToPay"
                                                    label="Betrag pro Fall"
                                                    value={amountToPayForCalculation}
                                                    error={amountToPayForCalculation === null || amountToPayForCalculation < 0}
                                                    helperText={amountToPayForCalculation === null || amountToPayForCalculation < 0 ? "Der zu zahlende Betrag pro Fall muss mindestens 0 betragen!" : ""}
                                                    type='number'
                                                    disabled={false}
                                                    setValueNumber={setAmountToPayForCalculation}
                                                    endButton={
                                                        <Tooltip title="Zu Kostenmodell hinzufügen">
                                                            <IconButton edge="end" color="primary" onClick={addAndSortPriceModel}>
                                                                <Add></Add>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                />
                                            </div>
                                        }
                                        {payModel.map((value) => {
                                            return <div>
                                                <ListItem disablePadding key={value.fromAmountCalculations} sx={{ background: "#EEEEEE" }}>
                                                    <ListItemButton sx={{ display: "flex", justifyContent: "space-between" }}>
                                                        <ListItemText primary={`Preis ab ${value.fromAmountCalculations} Berechnungen: ${value.amountPerCalculation}€ pro Berechnung`} sx={{
                                                            color: "#2D333B",

                                                        }}
                                                            primaryTypographyProps={{
                                                                fontWeight: 'bold',
                                                                fontSize: 14,
                                                            }} />
                                                        {user != null && user.permissions.includes("institution:edit") &&
                                                            <Tooltip title="Kostenmodell Eintrag löschen">
                                                                <IconButton edge="end" onClick={() => deleteAndSortPriceModel(value)}>
                                                                    <Delete></Delete>
                                                                </IconButton >
                                                            </Tooltip>
                                                        }
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider></Divider>
                                            </div>
                                        })}
                                    </List>

                                </div>
                            }
                        />
                    </div>
                }

            </div>
        </PageLayout>
    );
}

export default Institution;