import { Button } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import "../details.css"
import CrudTable, { ColumnDefinitionType } from "../../../shared/components/crud-table/crud-table";
import { Sort } from "../../../hooks/useSort.hook";
import { SortingDirection } from "../../../enums/sort-by";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { } from "../../../redux/auth.reducer";
import PageLayout from "../../../shared/components/page-layout";
import { Calculate, Update } from "@mui/icons-material";
import { clearHistory } from "../../../models/history";
import { CalculationBasisChangerAPI, CalculationBasisChangerAPIRequest, CalculationRunSort } from "../../../models/aid";
import TextFieldComponent from "../../../shared/components/text-field-component";
import AccordionComponent from "../../../shared/components/accordion-component";
import ConfirmCancelDialog from "../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog";
import { formatDateString, formatDateStringDayJsYearMonth, formatDayJsMonthYearToFirstDayOfMonthString, formatTimestampString } from "../../../helper/formatter";
import { fetchAllCalculationBasisChanger, startCalculationBasisChanger } from "../../../shared/requests/aid-request";
import { dayjsDateBeforeOrSameAsCurrentDate } from "../../../helper/validation";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { deDE } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

function CalculationBasisChanger() {
    const [calculationChangerRuns, setCalculationChangerRuns] = useState<CalculationBasisChangerAPI[]>([]);
    const [amountLastYear, setAmountLastYear] = useState<number>(0);
    const [amountCurrentYear, setAmountCurrentYear] = useState<number>(0);
    const [effectiveDate, setEffectiveDate] = useState<Dayjs | null>(null);
    const [factor, setFactor] = useState<number>(0);
    const [requestPushed, setRequestPushed] = useState<boolean>(false);
    const [calculatePercentagePushed, setCalculatePercentagPushed] = useState<boolean>(false);
    const [externalModified, setExternalModified] = useState<boolean>(false);
    const [showConfirmStartRunDialog, setShowConfirmStartRunDialog] = useState<boolean>(false);
    const isInitRender = useRef(true);
    const errorStartRunValues = effectiveDate === null || !dayjsDateBeforeOrSameAsCurrentDate(effectiveDate);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isInitRender.current && user) {
            clearHistory();
            fetchAllCalculationBasisChanger(user.institution.id).then(response => setCalculationChangerRuns(response.data))
            isInitRender.current = false;
        }
    }, [user]);

    const columns: ColumnDefinitionType<CalculationBasisChangerAPI, keyof CalculationBasisChangerAPI>[] = [
        {
            key: "changeStartTimestampString",
            header: "Bearbeitungsdatum",
        },
        {
            key: "changeStartUserString",
            header: "User",
        },
        {
            key: "effectiveDateString",
            header: "Stichtag",
        },
        {
            key: "factor",
            header: "Faktor",
        },
        {
            key: "statusString",
            header: "Status",
        },
        {
            key: "amountChangedCalculationBasis",
            header: "Anzahl geänderte Werte",
        },
    ]

    const [sortBy] = useState<Sort<CalculationRunSort>>({
        key: "startString",
        direction: SortingDirection.Descending,
    });

    const onStartRunClicked = () => {
        if (!errorStartRunValues) {
            setShowConfirmStartRunDialog(true)
            setRequestPushed(false)
        } else {
            setRequestPushed(true)
        }
    }

    const onStartRunCancel = () => {
        setShowConfirmStartRunDialog(false)
    }

    const onStartRunSubmit = () => {
        if (user && effectiveDate) {
            const effDate = formatDayJsMonthYearToFirstDayOfMonthString(effectiveDate)
            const changeParameters: CalculationBasisChangerAPIRequest = {
                effectiveDate: effDate,
                factor: factor
            }
            startCalculationBasisChanger(user.institution.id, changeParameters).then(() => {
                setShowConfirmStartRunDialog(false)
                setExternalModified(true)
            });
        }
    }

    const onCalculateFactorClicked = () => {
        if (amountCurrentYear > 0 && amountLastYear > 0) {
            setCalculatePercentagPushed(false);
            setFactor(amountCurrentYear / amountLastYear)
        } else {
            setCalculatePercentagPushed(true);
        }
    }





    return <PageLayout title={"Berechnungsgrundlagen aktualisieren"}>
        <ConfirmCancelDialog
            dialogTitle="Starten einer Berechnungsgrundlagen Aktualisierung"
            dialogInfo={`Sie veranlassen die Aktualisierungen der Abschlagswerte um dem Faktor ${factor} zum ${formatDateStringDayJsYearMonth(effectiveDate)}. Wollen Sie dies durchführen? Der Vorgang kann nicht rückgängig gemacht werden!`}
            onClickCancel={onStartRunCancel}
            onClickSubmit={onStartRunSubmit}
            showDialog={showConfirmStartRunDialog}

        />
        {user && user.permissions.includes("update_calculation_basis:start") && <div className="single-view">
            <div className="flex flex-column single-view-person__data-container">
                <div className="flex flex-row single-view__form-row-width">
                    <TextFieldComponent
                        required={true}
                        id="amountLastYear"
                        label="Beitragsbemessungsgrenze Alt"
                        type='number'
                        value={amountLastYear}
                        error={amountLastYear <= 0 && calculatePercentagePushed}
                        helperText={amountLastYear <= 0 && calculatePercentagePushed ? "Die Beitragsbemessungsgrenze muss größer 0 sein!" : ""}
                        disabled={false}
                        setValueNumber={setAmountLastYear}
                    />
                    <TextFieldComponent
                        required={true}
                        id="amountCurrentYear"
                        label="Beitragsbemessungsgrenze Neu"
                        type='number'
                        value={amountCurrentYear}
                        error={amountCurrentYear <= 0 && calculatePercentagePushed}
                        helperText={amountCurrentYear <= 0 && calculatePercentagePushed ? "Die Beitragsbemessungsgrenze muss größer 0 sein!" : ""}
                        disabled={false}
                        setValueNumber={setAmountCurrentYear}
                    />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <div className="single-view__empty-form-width"></div>
                    <Button startIcon={<Calculate />} variant="contained" size="large" onClick={onCalculateFactorClicked}
                        sx={{
                            height: 50,
                            width: 300,
                            color: "#001D53",
                            background: "#D4E7FF",
                            ':hover': {
                                background: 'primary.main', // theme.palette.primary.main
                                color: 'white',
                            },
                        }}>Faktor berechnen</Button>
                </div>
            </div>
            <div className="flex flex-row single-view__form-row-width single-view-empty-container">
            </div>
            <div className="flex flex-row single-view__form-row-width">
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="de"
                    localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                    <DatePicker
                        sx={{
                            "& .MuiOutlinedInput-root": { backgroundColor: "#EEEEEE" },
                            color: '#2D3335',
                            width: "100%"
                        }}
                        label={'Stichtag der Aktualisierung'}
                        views={['month', 'year']}
                        value={effectiveDate}
                        onChange={(newValue) => {
                            setEffectiveDate(newValue);
                        }} />
                </LocalizationProvider>
                <TextFieldComponent
                    required={true}
                    id="factor"
                    label="Faktor der Veränderung"
                    type='number'
                    value={factor}
                    error={false}
                    helperText=""
                    disabled={false}
                    setValueNumber={setFactor}
                />
            </div>
            {requestPushed && (effectiveDate === null || !dayjsDateBeforeOrSameAsCurrentDate(effectiveDate)) && <div className="flex flex-row single-view__form-row-width error-box">
                <div className="error">Der Stichtag darf maximal dem aktuellen Monat entsprechen!</div>
            </div>}
            <div className="flex flex-row single-view__form-row-width">
                <div className="single-view__empty-form-width"></div>
                <Button startIcon={<Update />} variant="contained" size="large" onClick={onStartRunClicked}
                    sx={{
                        height: 50,
                        width: 300,
                        color: "#001D53",
                        background: "#D4E7FF",
                        ':hover': {
                            background: 'primary.main', // theme.palette.primary.main
                            color: 'white',
                        },
                    }}>Durchführen</Button>
            </div>
        </div>}


        <div className="single-view">
            <div className='single-view-accordion'>
                <AccordionComponent
                    defaultExpanded={true}
                    label="Berechnungsgrundlagen Änderungsläufe"
                    startIcon={<Calculate></Calculate>}
                    validation={false}
                    error={false}
                    content={
                        <CrudTable
                            tableData={calculationChangerRuns}
                            externalModified={externalModified}
                            setExternalModified={setExternalModified}
                            columns={columns}
                            sortBy={sortBy}
                            fetchElementsWithId={fetchAllCalculationBasisChanger}
                            searchingId={user ? user.institution.id : "-1"}
                            hideSearchbar
                            dataFormatter={(element: CalculationBasisChangerAPI) => {
                                element.changeStartUserString = element.changeStartUser.lastname + ", " + element.changeStartUser.firstname;
                                element.statusString = element.status === "E_CALCULATION_BASIS_CHANGER_STATUS_OPEN" ? "offen" : element.status === "E_CALCULATION_BASIS_CHANGER_STATUS_RUNNING" ? "in Arbeit" : "abgeschlossen"
                                element.changeStartTimestampString = formatTimestampString(element.changeStartTimestamp);
                                element.effectiveDateString = formatDateString(element.effectiveDate)
                                return element;
                            }}
                        />
                    }></AccordionComponent>
            </div>
        </div>

    </PageLayout>
}

export default CalculationBasisChanger;