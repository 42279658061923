import { useEffect, useRef, useState } from "react";
import { SortingDirection } from "../../../enums/sort-by"
import CrudTable, {
    ColumnDefinitionType,
} from "../../../shared/components/crud-table/crud-table";
import PageLayout from "../../../shared/components/page-layout";
import { Sort } from "../../../hooks/useSort.hook";
import { useNavigate } from 'react-router-dom';
import { clearHistory } from '../../../models/history';
import { } from "../../../redux/auth.reducer";
import { AmountToPayDetailAPI } from "../../../models/admin";
import { fetchAllAmountsToPayForAllInstitutions } from "../../../shared/requests/admin-request";

function ListCosts() {
    const [sortBy] = useState<Sort<AmountToPayDetailAPI>>({
        key: "aidName",
        direction: SortingDirection.Descending,
    });

    const [amountsToPayPerInstitution, setAmountsToPayPerInstitution] = useState<AmountToPayDetailAPI[]>([]);
    const isInitRender = useRef(true);
    const navigate = useNavigate();

    useEffect(() => {
        clearHistory();
        if (isInitRender.current) {
            fetchAllAmountsToPayForAllInstitutions().then((response) => {
                setAmountsToPayPerInstitution(response.data);
            });
            isInitRender.current = false;
        }
    });

    const onShowDetailsClick = (entity: AmountToPayDetailAPI) => {
        navigate(`show/${entity.aid.id}`);
    }


    const columns: ColumnDefinitionType<AmountToPayDetailAPI, keyof AmountToPayDetailAPI>[] = [
        {
            key: "aidName",
            header: "Beihilfestelle",
        },
        {
            key: "amountAllCases",
            header: "Anzahl Fälle (Gesamt)",
        },
        {
            key: "amountAllToPay",
            header: "Entstandene Kosten (Gesamt)",
        }
    ];
    return (
        <PageLayout title={"Übersicht Institutionskosten"}>
            <CrudTable
                tableData={amountsToPayPerInstitution}
                columns={columns}
                sortBy={sortBy}
                callOnFindInPageClick={onShowDetailsClick}
                fetchElements={fetchAllAmountsToPayForAllInstitutions}
                dataFormatter={(data: AmountToPayDetailAPI) => {
                    let allCases = 0;
                    let allToPay = 0;
                    data.monthValues.forEach((element) => {
                        allCases += element.amountCases;
                        allToPay += element.amountToPay
                    });
                    data.aidName = data.aid.name
                    data.amountAllToPay = allToPay + "€";
                    data.amountAllCases = allCases
                    return data;
                }}
            />
        </PageLayout>
    );
}

export default ListCosts;
