export interface Address {
    id: string;
    effectiveDate: string;
    street: string;
    houseNumber: string | null;
    country: string;
    postalCode: string;
    location: string;
    poBox: string;
    poBoxPostalCode: string;
    addressSupplement: string;
    phoneNumber: string;
    fax: string;
    email: string;

}

export type AddressAPI = {
    id: string;
    effectiveDate: string;
    street: string;
    houseNumber: string | null;
    country: string;
    postalCode: string;
    location: string;
    poBox: string;
    poBoxPostalCode: string;
    addressSupplement: string;
    phoneNumber: string;
    fax: string;
    email: string;
};

export type AddressAPIRequest = {
    effectiveDate: string | null;
    street: string;
    houseNumber: string | null;
    country: string;
    postalCode: string;
    location: string;
    poBox: string;
    poBoxPostalCode: string;
    addressSupplement: string;
    phoneNumber: string;
    fax: string;
    email: string;
};

export type AddressSort = Pick<
    AddressAPI,
    "country" | "postalCode" | "location"
>;

export type CountrySelect = {
    id: string;
    name: string;
}

export const getCountries = () => [
    { id: "DE", name: "Deutschland" },
    { id: "AT", name: "Österreich" },
    { id: "CH", name: "Schweiz" },
    { id: "AF", name: "Afghanistan" },
    { id: "EG", name: "Ägypten" },
    { id: "AX", name: "Åland" },
    { id: "AL", name: "Albanien" },
    { id: "DZ", name: "Algerien" },
    { id: "AS", name: "Amerikanisch-Samoa" },
    { id: "VI", name: "Amerikanische Jungferninseln" },
    { id: "AD", name: "Andorra" },
    { id: "AO", name: "Angola" },
    { id: "AI", name: "Anguilla" },
    { id: "AQ", name: "Antarktis" },
    { id: "AG", name: "Antigua und Barbuda" },
    { id: "GQ", name: "Äquatorialguinea" },
    { id: "AR", name: "Argentinien" },
    { id: "AM", name: "Armenien" },
    { id: "AW", name: "Aruba" },
    { id: "AC", name: "Ascension" },
    { id: "AZ", name: "Aserbaidschan" },
    { id: "ET", name: "Äthiopien" },
    { id: "AU", name: "Australien" },
    { id: "BS", name: "Bahamas" },
    { id: "BH", name: "Bahrain" },
    { id: "BD", name: "Bangladesch" },
    { id: "BB", name: "Barbados" },
    { id: "BY", name: "Belarus (Weißrussland)" },
    { id: "BE", name: "Belgien" },
    { id: "BZ", name: "Belize" },
    { id: "BJ", name: "Benin" },
    { id: "BM", name: "Bermuda" },
    { id: "BT", name: "Bhutan" },
    { id: "BO", name: "Bolivien" },
    { id: "BA", name: "Bosnien und Herzegowina" },
    { id: "BW", name: "Botswana" },
    { id: "BV", name: "Bouvetinsel" },
    { id: "BR", name: "Brasilien" },
    { id: "VG", name: "Britische Jungferninseln" },
    { id: "IO", name: "Britisches Territorium im Indischen Ozean" },
    { id: "BN", name: "Brunei Darussalam" },
    { id: "BG", name: "Bulgarien" },
    { id: "BF", name: "Burkina Faso" },
    { id: "BI", name: "Burundi" },
    { id: "EA", name: "Ceuta, Melilla" },
    { id: "CL", name: "Chile" },
    { id: "CN", name: "Volksrepublik China" },
    { id: "CP", name: "Clipperton" },
    { id: "CK", name: "Cookinseln" },
    { id: "CR", name: "Costa Rica" },
    { id: "CI", name: "Côte d'Ivoire (Elfenbeinküste)" },
    { id: "DK", name: "Dänemark" },
    { id: "DG", name: "Diego Garcia" },
    { id: "DM", name: "Dominica" },
    { id: "DO", name: "Dominikanische Republik" },
    { id: "DJ", name: "Dschibuti" },
    { id: "EC", name: "Ecuador" },
    { id: "SV", name: "El Salvador" },
    { id: "ER", name: "Eritrea" },
    { id: "EE", name: "Estland" },
    { id: "FK", name: "Falklandinseln" },
    { id: "FO", name: "Färöer" },
    { id: "FJ", name: "Fidschi" },
    { id: "FI", name: "Finnland" },
    { id: "FR", name: "Frankreich" },
    { id: "GF", name: "Französisch-Guayana" },
    { id: "PF", name: "Französisch-Polynesien" },
    { id: "GA", name: "Gabun" },
    { id: "GM", name: "Gambia" },
    { id: "GE", name: "Georgien" },
    { id: "GH", name: "Ghana" },
    { id: "GI", name: "Gibraltar" },
    { id: "GD", name: "Grenada" },
    { id: "GR", name: "Griechenland" },
    { id: "GL", name: "Grönland" },
    { id: "GB", name: "Großbritannien" },
    { id: "GP", name: "Guadeloupe" },
    { id: "GU", name: "Guam" },
    { id: "GT", name: "Guatemala" },
    { id: "GG", name: "Guernsey (Kanalinsel)" },
    { id: "GN", name: "Guinea" },
    { id: "GW", name: "Guinea-Bissau" },
    { id: "GY", name: "Guyana" },
    { id: "HT", name: "Haiti" },
    { id: "HM", name: "Heard- und McDonald-Inseln" },
    { id: "HN", name: "Honduras" },
    { id: "HK", name: "Hongkong" },
    { id: "IN", name: "Indien" },
    { id: "ID", name: "Indonesien" },
    { id: "IM", name: "Insel Man" },
    { id: "IQ", name: "Irak" },
    { id: "IR", name: "Iran" },
    { id: "IE", name: "Irland" },
    { id: "IS", name: "Island" },
    { id: "IL", name: "Israel" },
    { id: "IT", name: "Italien" },
    { id: "JM", name: "Jamaika" },
    { id: "JP", name: "Japan" },
    { id: "YE", name: "Jemen" },
    { id: "JE", name: "Jersey (Kanalinsel)" },
    { id: "JO", name: "Jordanien" },
    { id: "KY", name: "Kaimaninseln" },
    { id: "KH", name: "Kambodscha" },
    { id: "CM", name: "Kamerun" },
    { id: "CA", name: "Kanada" },
    { id: "IC", name: "Kanarische Inseln" },
    { id: "CV", name: "Kap Verde" },
    { id: "KZ", name: "Kasachstan" },
    { id: "QA", name: "Katar" },
    { id: "KE", name: "Kenia" },
    { id: "KG", name: "Kirgisistan" },
    { id: "KI", name: "Kiribati" },
    { id: "CC", name: "Kokosinseln" },
    { id: "CO", name: "Kolumbien" },
    { id: "KM", name: "Komoren" },
    { id: "CD", name: "Demokratische Republik Kongo" },
    { id: "KP", name: "Demokratische Volksrepublik Korea (Nordkorea)" },
    { id: "KR", name: "Republik Korea (Südkorea)" },
    { id: "HR", name: "Kroatien" },
    { id: "CU", name: "Kuba" },
    { id: "KW", name: "Kuwait" },
    { id: "LA", name: "Laos" },
    { id: "LS", name: "Lesotho" },
    { id: "LV", name: "Lettland" },
    { id: "LB", name: "Libanon" },
    { id: "LR", name: "Liberia" },
    { id: "LY", name: "Libyen" },
    { id: "LI", name: "Liechtenstein" },
    { id: "LT", name: "Litauen" },
    { id: "LU", name: "Luxemburg" },
    { id: "MO", name: "Macao" },
    { id: "MG", name: "Madagaskar" },
    { id: "MW", name: "Malawi" },
    { id: "MY", name: "Malaysia" },
    { id: "MV", name: "Malediven" },
    { id: "ML", name: "Mali" },
    { id: "MT", name: "Malta" },
    { id: "MA", name: "Marokko" },
    { id: "MH", name: "Marshallinseln" },
    { id: "MQ", name: "Martinique" },
    { id: "MR", name: "Mauretanien" },
    { id: "MU", name: "Mauritius" },
    { id: "YT", name: "Mayotte" },
    { id: "MK", name: "Mazedonien" },
    { id: "MX", name: "Mexiko" },
    { id: "FM", name: "Mikronesien" },
    { id: "MD", name: "Moldawien (Republik Moldau)" },
    { id: "MC", name: "Monaco" },
    { id: "MN", name: "Mongolei" },
    { id: "ME", name: "Montenegro" },
    { id: "MS", name: "Montserrat" },
    { id: "MZ", name: "Mosambik" },
    { id: "MM", name: "Myanmar (Burma)" },
    { id: "NA", name: "Namibia" },
    { id: "NR", name: "Nauru" },
    { id: "NP", name: "Nepal" },
    { id: "NC", name: "Neukaledonien" },
    { id: "NZ", name: "Neuseeland" },
    { id: "NI", name: "Nicaragua" },
    { id: "NL", name: "Niederlande" },
    { id: "AN", name: "Niederländische Antillen" },
    { id: "NE", name: "Niger" },
    { id: "NG", name: "Nigeria" },
    { id: "NU", name: "Niue" },
    { id: "MP", name: "Nördliche Marianen" },
    { id: "NF", name: "Norfolkinsel" },
    { id: "NO", name: "Norwegen" },
    { id: "OM", name: "Oman" },
    { id: "XO", name: "Orbit" },
    { id: "AT", name: "Österreich" },
    { id: "TL", name: "Osttimor (Timor-Leste)" },
    { id: "PK", name: "Pakistan" },
    { id: "PS", name: "Palästinensische Autonomiegebiete" },
    { id: "PW", name: "Palau" },
    { id: "PA", name: "Panama" },
    { id: "PG", name: "Papua-Neuguinea" },
    { id: "PY", name: "Paraguay" },
    { id: "PE", name: "Peru" },
    { id: "PH", name: "Philippinen" },
    { id: "PN", name: "Pitcairninseln" },
    { id: "PL", name: "Polen" },
    { id: "PT", name: "Portugal" },
    { id: "PR", name: "Puerto Rico" },
    { id: "TW", name: "Republik China (Taiwan)" },
    { id: "CG", name: "Republik Kongo" },
    { id: "RE", name: "Réunion" },
    { id: "RW", name: "Ruanda" },
    { id: "RO", name: "Rumänien" },
    { id: "RU", name: "Russische Föderation" },
    { id: "BL", name: "Saint-Barthélemy" },
    { id: "MF", name: "Saint-Martin" },
    { id: "SB", name: "Salomonen" },
    { id: "ZM", name: "Sambia" },
    { id: "WS", name: "Samoa" },
    { id: "SM", name: "San Marino" },
    { id: "ST", name: "São Tomé und Príncipe" },
    { id: "SA", name: "Saudi-Arabien" },
    { id: "SE", name: "Schweden" },
    { id: "CH", name: "Schweiz" },
    { id: "SN", name: "Senegal" },
    { id: "RS", name: "Serbien" },
    { id: "SC", name: "Seychellen" },
    { id: "SL", name: "Sierra Leone" },
    { id: "ZW", name: "Simbabwe" },
    { id: "SG", name: "Singapur" },
    { id: "SK", name: "Slowakei" },
    { id: "SI", name: "Slowenien" },
    { id: "SO", name: "Somalia" },
    { id: "ES", name: "Spanien" },
    { id: "LK", name: "Sri Lanka" },
    { id: "SH", name: "St. Helena" },
    { id: "KN", name: "St. Kitts und Nevis" },
    { id: "LC", name: "St. Lucia" },
    { id: "PM", name: "Saint-Pierre und Miquelon" },
    { id: "VC", name: "St. Vincent und die Grenadinen" },
    { id: "ZA", name: "Südafrika" },
    { id: "SD", name: "Sudan" },
    { id: "GS", name: "Südgeorgien und die Südlichen Sandwichinseln" },
    { id: "SR", name: "Suriname" },
    { id: "SJ", name: "Svalbard und Jan Mayen" },
    { id: "SZ", name: "Swasiland" },
    { id: "SY", name: "Syrien" },
    { id: "TJ", name: "Tadschikistan" },
    { id: "TZ", name: "Tansania" },
    { id: "TH", name: "Thailand" },
    { id: "TG", name: "Togo" },
    { id: "TK", name: "Tokelau" },
    { id: "TO", name: "Tonga" },
    { id: "TT", name: "Trinidad und Tobago" },
    { id: "TA", name: "Tristan da Cunha" },
    { id: "TD", name: "Tschad" },
    { id: "CZ", name: "Tschechische Republik" },
    { id: "TN", name: "Tunesien" },
    { id: "TR", name: "Türkei" },
    { id: "TM", name: "Turkmenistan" },
    { id: "TC", name: "Turks- und Caicosinseln" },
    { id: "TV", name: "Tuvalu" },
    { id: "UG", name: "Uganda" },
    { id: "UA", name: "Ukraine" },
    { id: "HU", name: "Ungarn" },
    { id: "UY", name: "Uruguay" },
    { id: "UZ", name: "Usbekistan" },
    { id: "VU", name: "Vanuatu" },
    { id: "VA", name: "Vatikanstadt" },
    { id: "VE", name: "Venezuela" },
    { id: "AE", name: "Vereinigte Arabische Emirate" },
    { id: "US", name: "Vereinigte Staaten von Amerika (USA)" },
    { id: "GB", name: "Vereinigtes Königreich Großbritannien und Nordirland" },
    { id: "VN", name: "Vietnam" },
    { id: "WF", name: "Wallis und Futuna" },
    { id: "CX", name: "Weihnachtsinsel" },
    { id: "EH", name: "Westsahara" },
    { id: "CF", name: "Zentralafrikanische Republik" },
    { id: "CY", name: "Zypern" }
]

export type AddressShortKey = keyof Address;
