import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
} from "@mui/material";
import "./dialog.css";
import { Theme, useTheme } from "@mui/material";
import ErrorDisplay from "../../error-display";
import SubmitButtonsSingleRunErrorDialog from "../submit-buttons/submit-button-single-run-error";
import { saveAs } from 'file-saver';

export interface DeleteProps {
    showDialog: boolean;
    runErrors: string[] | null;
    onClickSubmit: () => void;
}


const SingleRunErrorDialog = ({
    showDialog,
    onClickSubmit,
    runErrors,
}: DeleteProps) => {
    const theme: Theme = useTheme();
    const onClickDownloadError = () => {
        let content = "Folgende Fehler sind im Berechnungslauf aufgetreten:\n\n";
        if (runErrors !== null) {
            runErrors.forEach(element => {
                content += element + "\n\n";
            })
        }
        const file = new Blob([content], { type: 'text/plain' });
        saveAs(file, `run_error_log.txt`);
    }
    return (
        <Dialog open={showDialog}>
            <DialogTitle
                sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
            >
                Bei Beitragsberechnungslauf sind folgende Fehler aufgetreten
            </DialogTitle>
            <DialogContent sx={{ marginTop: "1rem" }} className="DialogContent">
                <ErrorDisplay>
                    <DialogContentText>
                        {(runErrors === null || runErrors.length === 0) && "Es sind keine Fehler aufgetreten!"}
                        {(runErrors !== null && runErrors.length > 0) && runErrors.map(error => <div><p>{error}</p><Divider></Divider></div>)}
                    </DialogContentText>
                </ErrorDisplay>
            </DialogContent>
            <DialogActions>
                <SubmitButtonsSingleRunErrorDialog
                    downloadText="Fehler herunterladen"
                    onClickDownload={onClickDownloadError}
                    submitText="OK"
                    onClickSubmit={onClickSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

export default SingleRunErrorDialog;
