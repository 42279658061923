import SelectComponent from '../../../../shared/components/select-component';
import { InstitutionSelect } from '../../../../models/institution';
import { User } from '../../../../models/user';
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { Insights } from '@mui/icons-material';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { ContractTypes, getAllContractTypes } from '../../../../models/person-in-need-of-care';

type SelectProps = {
    user: User | null;
    savePushed: boolean
    disabled: boolean
    aidValues: InstitutionSelect[];
    insuranceValues: InstitutionSelect[];
    pensionInstitutionValues: InstitutionSelect[];
    caringPersonExternalIdentifier: string;
    setCaringPersonExternalIdentifier: (value: string) => void;
    conceptOfOrder: string | null;
    setConceptOfOrder: (value: string | null) => void;
    pensionInstitution: InstitutionSelect | null;
    setPensionInstitution: (value: InstitutionSelect | null) => void;
    insurance: InstitutionSelect | null;
    setInsurance: (value: InstitutionSelect | null) => void;
    aid: InstitutionSelect | null;
    setAid: (value: InstitutionSelect | null) => void;
    note: string | null;
    setNote: (value: string | null) => void;
    contractType: ContractTypes | null;
    setContractType: (value: ContractTypes | null) => void;
    labelConceptOfOrderPensionInstitution: string;
    formError: boolean;
    validation?: boolean;
    setValuesChanged?: (val: boolean) => void;
};

const CaringPersonForm = ({
    user,
    savePushed,
    disabled,
    aidValues,
    insuranceValues,
    pensionInstitutionValues,
    caringPersonExternalIdentifier,
    setCaringPersonExternalIdentifier,
    conceptOfOrder,
    setConceptOfOrder,
    pensionInstitution,
    setPensionInstitution,
    insurance,
    setInsurance,
    aid,
    setAid,
    note,
    setNote,
    contractType,
    setContractType,
    labelConceptOfOrderPensionInstitution,
    formError,
    validation,
    setValuesChanged

}: SelectProps) => {
    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={false}
            label="Versicherungsdaten"
            startIcon={<Insights></Insights>}
            validation={validation}
            error={formError}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        <div className="flex flex-row single-view__form-row-width">
                            {(user?.institution.type !== "INSTITUTION_TYPE_PRIVATE_HEALTH_INSURANCE" && user?.institution.type !== "INSTITUTION_TYPE_STATUTORY_HEALTH_INSURANCE") && <SelectComponent
                                selectData={insuranceValues}
                                tooltip="Es wurden noch keine Versicherungen angelegt."
                                className="single-view__text-field"
                                label="Meldende Versicherung"
                                value={insurance?.name || ""}
                                onChange={(value: string) => setInsurance(insuranceValues.find(({ name }) => name === value) || null)}
                                disabled={disabled || user?.institution.type === "INSTITUTION_TYPE_PRIVATE_HEALTH_INSURANCE" || user?.institution.type === "INSTITUTION_TYPE_STATUTORY_HEALTH_INSURANCE"}
                                error={savePushed && !insurance}
                            ></SelectComponent>
                            }
                            {user?.institution.type !== "INSTITUTION_TYPE_AID" && <SelectComponent
                                selectData={aidValues}
                                tooltip="Es wurden noch keine Beihilfestellen angelegt."
                                className="single-view__text-field"
                                label="Beihilfestelle"
                                value={aid?.name || ""}
                                onChange={(value: string) => setAid(aidValues.find(({ name }) => name === value) || null)}
                                disabled={disabled || user?.institution.type === "INSTITUTION_TYPE_AID"}
                                error={savePushed && !aid}
                            ></SelectComponent>}
                            <TextFieldComponent
                                required={true}
                                id="caringPersonExternalIdentifier"
                                label="Externer Schlüssel der Pflegeperson"
                                type='text'
                                value={caringPersonExternalIdentifier}
                                error={caringPersonExternalIdentifier === "" && savePushed}
                                helperText={
                                    caringPersonExternalIdentifier === "" && savePushed
                                        ? "Der Externe Schlüssel des Pflegers darf nicht leer sein!"
                                        : ""
                                }
                                disabled={disabled}
                                setValueString={setCaringPersonExternalIdentifier}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={pensionInstitutionValues}
                                tooltip="Es wurden noch keine Versorgungseinrichtungen angelegt."
                                className="single-view__text-field"
                                label="Versorgungseinrichtung"
                                value={pensionInstitution?.name || ""}
                                onChange={(value: string) => setPensionInstitution(pensionInstitutionValues.find(({ name }) => name === value) || null)}
                                disabled={disabled}
                                notRequired
                                error={false}
                            ></SelectComponent>
                            <TextFieldComponent
                                required={pensionInstitution !== null && pensionInstitution.id !== ""}
                                id="conceptOfOrder"
                                label={labelConceptOfOrderPensionInstitution}
                                type='text'
                                value={conceptOfOrder}
                                error={conceptOfOrder === "" && pensionInstitution !== null && pensionInstitution.id !== "" && savePushed}
                                helperText={
                                    conceptOfOrder === "" && pensionInstitution !== null && pensionInstitution.id !== "" && savePushed
                                        ? "Der Ordnungsbegriff darf nicht leer sein, wenn eine Versorgungseinrichtung gewählt wurde!"
                                        : ""
                                }
                                disabled={disabled || pensionInstitution === null || pensionInstitution.id === ""}
                                setValueString={setConceptOfOrder}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width single-view-empty-container">
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={getAllContractTypes(user, disabled)}
                                tooltip="Es wurden noch keine Vertragsarten angelegt."
                                className="single-view__text-field"
                                label="Vertragsart"
                                value={contractType?.name || ""}
                                onChange={(value: string) => setContractType(getAllContractTypes(user, disabled).find(({ name }) => name === value) || null)}
                                disabled={disabled}
                                error={savePushed && !contractType}
                                setValueChanged={setValuesChanged}
                            ></SelectComponent>
                            <TextFieldComponent
                                required={false}
                                id="note"
                                label="Notiz"
                                type='text'
                                value={note}
                                error={false}
                                helperText=""
                                disabled={disabled}
                                setValueString={setNote}
                            />
                        </div>
                    </div>
                </div>
            }
        />
    </div>
}
export default CaringPersonForm;