import SelectComponent from '../../../../shared/components/select-component';
import { PersonInNeedOfCareSelect } from '../../../../models/person-in-need-of-care';
import { CaringPersonSelect } from '../../../../models/caring-person';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { Group } from '@mui/icons-material';
import TextFieldComponent from '../../../../shared/components/text-field-component';

type SelectProps = {
    savePushed: boolean
    disabled: boolean
    caringPersonDisabled?: boolean
    personInNeedOfCareDisabled?: boolean
    caringPersonValues: CaringPersonSelect[];
    personInNeedOfCareValues: PersonInNeedOfCareSelect[];
    caringPerson: CaringPersonSelect | null;
    setCaringPersonValue: (value: string) => void;
    personInNeedOfCare: PersonInNeedOfCareSelect | null;
    setPersonInNeedOfCareValue: (value: string) => void;
    formError: boolean;
    validation?: boolean;
    earliestEffectiveDatePersonInNeedOfCare: string;
    setEarliestEffectiveDatePersonInNeedOfCare: (value: string) => void;
    earliestEffectiveDateCaringPerson: string;
    setEarliestEffectiveDateCaringPerson: (value: string) => void;
    note: string | null;
    setNote: (value: string | null) => void;

};

const PersonConnectionForm = ({
    savePushed,
    disabled,
    caringPersonDisabled,
    personInNeedOfCareDisabled,
    caringPersonValues,
    personInNeedOfCareValues,
    caringPerson,
    setCaringPersonValue,
    personInNeedOfCare,
    setPersonInNeedOfCareValue,
    earliestEffectiveDateCaringPerson,
    earliestEffectiveDatePersonInNeedOfCare,
    setEarliestEffectiveDateCaringPerson,
    setEarliestEffectiveDatePersonInNeedOfCare,
    formError,
    validation,
    note,
    setNote,

}: SelectProps) => {

    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={true}
            label="Personenverknüpfung"
            startIcon={<Group></Group>}
            error={formError}
            validation={validation}
            content={
                <div>
                    <div className="flex flex-row single-view__form-row-width">
                        <SelectComponent
                            selectData={personInNeedOfCareValues}
                            tooltip="Es wurden noch keine Bedürftigen angelegt."
                            className="single-view__text-field"
                            label="Bedürftige/r "
                            personGenderSelect
                            value={personInNeedOfCare?.name || ""}
                            onChange={setPersonInNeedOfCareValue}
                            disabled={disabled || (personInNeedOfCareDisabled !== null && personInNeedOfCareDisabled === true)}
                            error={savePushed && !personInNeedOfCare}
                        ></SelectComponent>
                        <SelectComponent
                            selectData={caringPersonValues}
                            tooltip="Es wurden noch keine Pflegepersonen angelegt."
                            className="single-view__text-field"
                            label="Pflegeperson "
                            personGenderSelect
                            value={caringPerson?.name || ""}
                            onChange={setCaringPersonValue}
                            disabled={disabled || (caringPersonDisabled !== null && caringPersonDisabled === true)}
                            error={savePushed && !caringPerson}
                        ></SelectComponent>
                    </div>
                    <div className="flex flex-row single-view__form-row-width">
                        <TextFieldComponent
                            required={false}
                            id="earliestEffectiveDatePersonInNeedOfCare"
                            label="Frühestes Wirksamkeitsdatum Bedürftige/r"
                            type='date'
                            value={earliestEffectiveDatePersonInNeedOfCare}
                            error={false}
                            helperText={""}
                            disabled={true}
                            setValueString={setEarliestEffectiveDatePersonInNeedOfCare}
                        />
                        <TextFieldComponent
                            required={false}
                            id="earliestEffectiveDateCaringPerson"
                            label="Frühestes Wirksamkeitsdatum Pflegeperson"
                            type='date'
                            value={earliestEffectiveDateCaringPerson}
                            error={false}
                            helperText={""}
                            disabled={true}
                            setValueString={setEarliestEffectiveDateCaringPerson}
                        />
                    </div>
                    <div className="flex flex-row single-view__form-row-width single-view-empty-container">
                    </div>
                    <div className="flex flex-row single-view__form-row-width">
                        <TextFieldComponent
                            required={false}
                            id="note"
                            label="Notiz"
                            type='text'
                            value={note}
                            error={false}
                            helperText=""
                            disabled={disabled}
                            setValueString={setNote}
                        />
                        <div className="single-view__empty-form-width"></div>
                    </div>
                </div>
            }
        />
    </div >
}
export default PersonConnectionForm;