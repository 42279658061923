import { InstitutionAPI, Institution } from "../models/institution";
import { BusinessTransactionAPI } from "./business-transaction";

export interface CalculationBasis {
    id: string;
    from: string;
    status: string;
    until: string | null;
    amount: number
    amountFlag: string;
    client: Institution;
};

export type CalculationBasisAPI = {
    id: string;
    from: string;
    status: string;
    until: string | null;
    amount: number
    amountFlag: string;
    client: InstitutionAPI | null;
    fromUntilString: string;
};

export type CalculationBasisHistoryAPI = {
    id: string;
    historyNumber: string;
    from: string;
    status: string;
    until: string | null;
    amount: number
    amountFlag: string;
    client: InstitutionAPI;
    createTimestamp: string;
    deleteTimestamp: string | null;
    createTransaction: BusinessTransactionAPI;
    deleteTransaction: BusinessTransactionAPI | null;
    imported: boolean;
};

export type CalculationBasisAPIRequest = {
    id: string;
    from: string;
    status: string;
    until: string | null;
    amount: number
    amountFlag: string;
};

export type CalculationBasisAmountFlagSelect = {
    id: string;
    name: string;
};

export const getCalculationBasisAmountFlag = () => {
    return [
        {
            id: "AMOUNT_TYPE_MONTH",
            name: "Monatswert"
        },
        {
            id: "AMOUNT_TYPE_YEAR",
            name: "Jahreswert"
        }
    ]
}

export type CalculationBasisSort = Pick<
    CalculationBasisAPI,
    "amount" | "id" | "amountFlag"
>;

export type CalculationBasisHistorySort = Pick<
    CalculationBasisHistoryAPI,
    "amount" | "id" | "amountFlag" | "historyNumber"
>;

export type CalculationBasisShortKey = keyof CalculationBasis;
