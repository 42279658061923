import TextFieldComponent from "../text-field-component";
import { Today } from "@mui/icons-material";
import AccordionComponent from "../accordion-component";

type SelectProps = {
    savePushed: boolean;
    disabled: boolean;
    effectiveDate: string;
    setEffectiveDate: (value: string) => void;
    formError: boolean;
    validation?: boolean
    setValuesChanged?: (val: boolean) => void;
};

const EffectiveDateForm = ({
    savePushed,
    disabled,
    effectiveDate,
    setEffectiveDate,
    formError,
    validation,
    setValuesChanged
}: SelectProps) => {

    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={true}
            label="Wirksamkeitsdatum"
            startIcon={<Today></Today>}
            error={formError}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="effectiveDate"
                                label="Wirksamkeitsdatum"
                                type='date'
                                value={effectiveDate}
                                error={effectiveDate === "" && savePushed}
                                helperText={
                                    effectiveDate === "" && savePushed
                                        ? "Das Wirksamkeitsdatum darf nicht leer sein!"
                                        : ""
                                }
                                disabled={disabled}
                                setValueString={setEffectiveDate}
                                setValueChanged={setValuesChanged}
                            />
                            <div className="single-view__empty-form-width"></div>
                        </div>
                    </div >
                </div>
            }
        />
    </div>
}
export default EffectiveDateForm;