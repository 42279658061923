import { resourceAxiosInstance } from "../../config/axios.config";
import { AmountToPayDetailAPI } from "../../models/admin";

export const fetchAllAmountsToPayForAllInstitutions = () =>
    resourceAxiosInstance.get<AmountToPayDetailAPI[]>(`/admin/pay`, {
        cache: false,
    });

export const fetchAllAmountsToPayForSingleInstitutions = (institutionId: string) =>
    resourceAxiosInstance.get<AmountToPayDetailAPI>(`/admin/pay/${institutionId}`, {
        cache: false,
    });