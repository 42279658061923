
import { Button } from "@mui/material";
import { FreeBreakfast, Restore, Save } from "@mui/icons-material";
import { useState } from "react";
import { updateProcessDescription } from "../../../requests/user.requests";
import { ProcessDescriptionRequestAPI } from "../../../../models/user";
import TextFieldComponentSubmitButton from "../../text-field-compontent-submit-button";

export interface SubmitProps {
    submitText: string;
    cancelText: string;
    pauseText: string;
    submitLink?: string;
    cancelLink?: string;
    pauseLink?: string;
    isValid?: boolean;
    onClickSubmit?: () => void;
    onClickPause?: () => void;
    onClickCancel?: () => void;
    processId: string | undefined;
}

function SubmitButtonsHistory({
    submitText,
    cancelText,
    pauseText,
    onClickCancel,
    onClickSubmit,
    onClickPause,
    processId
}: SubmitProps) {
    const [processDescription, setProcessDescription] = useState<string>("");

    const updateDescription = (afterFunction: (() => void) | undefined) => {
        if (processId) {
            const descriptionAPI: ProcessDescriptionRequestAPI = {
                id: processId,
                description: processDescription
            }
            updateProcessDescription(descriptionAPI).then(() => {
                if (afterFunction) {
                    afterFunction();
                }
            })
        }
    }

    return (
        <>
            <TextFieldComponentSubmitButton
                required={false}
                id="processDescription"
                label="Beschreibung"
                value={processDescription}
                error={false}
                helperText=""
                type='text'
                disabled={false}
                setValueString={setProcessDescription}
            />
            <Button variant="contained" onClick={() => updateDescription(onClickSubmit)}>
                <Save sx={{ mr: 1 }}></Save>
                {submitText}
            </Button>
            <Button variant="contained" onClick={() => updateDescription(onClickPause)}>
                <FreeBreakfast sx={{ mr: 1 }}></FreeBreakfast>
                {pauseText}
            </Button>
            <Button variant="contained" onClick={() => updateDescription(onClickCancel)}>
                <Restore sx={{ mr: 1 }}></Restore>
                {cancelText}
            </Button>
        </>
    );
}

export default SubmitButtonsHistory;
