import { useEffect, useRef, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import { InstitutionAPI, InstitutionSort } from "../../../../models/institution";
import {
    fetchAids,
} from "../../../../shared/requests/institution.requests";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import CrudTable, { ColumnDefinitionType } from "../../../../shared/components/crud-table/crud-table";
import { clearHistory } from "../../../../models/history";
import { formatAddressString, formatInstitutionTypeString, formatSuccessorString } from "../../../../helper/formatter";

function ShowAids() {
    const [sortBy] = useState<Sort<InstitutionSort>>({
        key: "name",
        direction: SortingDirection.Descending,
    });

    const [institutions, setInstitutions] = useState<InstitutionAPI[]>([]);
    const isInitRender = useRef(true);

    useEffect(() => {
        clearHistory();
        if (isInitRender.current) {
            fetchAids().then((response) => {
                setInstitutions(response.data);
            });
            isInitRender.current = false;
        }
    });

    const columns: ColumnDefinitionType<InstitutionAPI, keyof InstitutionAPI>[] = [
        {
            key: "id",
            header: "ID",
        },

        {
            key: "name",
            header: "Name",
        },
        {
            key: "typeString",
            header: "Institutions-Typ",
        },
        {
            key: "successorID",
            header: "Nachfolger",
        },
        {
            key: "addressString",
            header: "Anschrift",
        }
    ];
    return (
        <PageLayout title={"Übersicht Beihilfestellen"}>
            <CrudTable
                tableData={institutions}
                columns={columns}
                sortBy={sortBy}
                fetchElements={fetchAids}
                dataFormatter={(element: InstitutionAPI) => {
                    element.successorID = formatSuccessorString(element.successor)
                    element.addressString = formatAddressString(element.address)
                    element.typeString = formatInstitutionTypeString(element.type, "Ohne Institutionstyp")
                    return element;
                }}
            />
        </PageLayout>
    );
}

export default ShowAids;
