import { AddressAPI, Address, AddressAPIRequest } from "../models/address";
import { PersonAPI, Person, PersonAPIRequest } from "../models/person";
import { InstitutionAPI, Institution } from "../models/institution";
import { BusinessTransactionAPI } from "./business-transaction";
import { AidLevel, AidLevelAPI } from "./aid";
import { User } from "./user";
export interface PersonInNeedOfCare {
    id: string;
    effectiveDate: string;
    status: string;
    validity: string;
    person: Person;
    address: Address | null;
    conceptOfOrder1: string;
    conceptOfOrder2: string;
    externalConceptOfOrder1: string | null;
    externalConceptOfOrder2: string | null;
    specialConceptOfOrder1: string | null;
    specialConceptOfOrder2: string | null;
    aidLevel: AidLevel | null;
    contractType: string;
    insurance: Institution;
    client: Institution;
    note: string | null;


}

export type PersonInNeedOfCareAPI = {
    id: string;
    historyNumber: string;
    effectiveDate: string;
    status: string;
    validity: string;
    person: PersonAPI;
    address: AddressAPI | null;
    conceptOfOrder1: string;
    conceptOfOrder2: string | null;
    externalConceptOfOrder1: string | null;
    externalConceptOfOrder2: string | null;
    specialConceptOfOrder1: string | null;
    specialConceptOfOrder2: string | null;
    aidLevel: AidLevelAPI | null;
    contractType: string;
    insurance: InstitutionAPI;
    client: InstitutionAPI;
    note: string | null;
    createTimestamp: string;
    deleteTimestamp: string | null;
    createTransaction: BusinessTransactionAPI;
    deleteTransaction: BusinessTransactionAPI | null;
    imported: boolean;
    personName: string;
    addressString: string;
    validityString: string;
    statusString: string;
    createDescriptionString: string;
    deleteDescriptionString: string;
};

export type PersonInNeedOfCareAPIRequest = {
    id: string;
    effectiveDate: string;
    status: string;
    person: PersonAPIRequest;
    address: AddressAPIRequest | null;
    conceptOfOrder1: string;
    conceptOfOrder2: string | null;
    externalConceptOfOrder1: string | null;
    externalConceptOfOrder2: string | null;
    specialConceptOfOrder1: string | null;
    specialConceptOfOrder2: string | null;
    aidLevelIdentifier: number;
    contractType: string;
    insuranceIdentifier: number;
    aidIdentifier: number;
    processIdentifier: string;
    note: string | null;
};

export type PersonInNeedOfCareSelect = {
    id: string;
    name: string;
    realValue: number;
    genderId: string;
};

export interface ContractTypes {
    id: string,
    name: string
}

export const getAllContractTypes = (user: User | null, isShowMode: boolean) => {
    const contractTypes = [];
    if (user) {
        if (isShowMode) {
            if (user.permissions.includes("data_normal:read")) {
                contractTypes.push({
                    id: "E_CONTRACT_TYPE_NORMAL",
                    name: "Normal",
                })
            }
            if (user.permissions.includes("data_employee:read")) {
                contractTypes.push({
                    id: "E_CONTRACT_TYPE_EMPLOYEE",
                    name: "Mitarbeiter",
                })
            }
            if (user.permissions.includes("data_vip:read")) {
                contractTypes.push({
                    id: "E_CONTRACT_TYPE_VIP",
                    name: "VIP",
                })
            }
        } else {
            if (user.permissions.includes("data_normal:edit")) {
                contractTypes.push({
                    id: "E_CONTRACT_TYPE_NORMAL",
                    name: "Normal",
                })
            }
            if (user.permissions.includes("data_employee:edit")) {
                contractTypes.push({
                    id: "E_CONTRACT_TYPE_EMPLOYEE",
                    name: "Mitarbeiter",
                })
            }
            if (user.permissions.includes("data_vip:edit")) {
                contractTypes.push({
                    id: "E_CONTRACT_TYPE_VIP",
                    name: "VIP",
                })
            }
        }
    }
    return contractTypes;
}

export type PersonInNeedOfCareSort = Pick<
    PersonInNeedOfCareAPI,
    "personName" | "id" | "addressString" | "effectiveDate" | "conceptOfOrder1" | "validityString"
>;

export type PersonInNeedOfCareHistorySort = Pick<
    PersonInNeedOfCareAPI,
    "person" | "id" | "address" | "historyNumber"
>;

export type PersonInNeedOfCareShortKey = keyof PersonInNeedOfCare;
