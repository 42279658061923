import { Chrono } from "react-chrono";
import { TimeLineItemModelHistory } from "../../models/timeline";
import { CloudCircle, Dangerous, PublishedWithChanges } from "@mui/icons-material";
import { useState } from "react";

type TimelineProps = {
    items: TimeLineItemModelHistory[];
    defaultChronoIndex: number;
    onItemSelected: (index: number) => void;
};

const Timeline = ({
    items,
    defaultChronoIndex,
    onItemSelected
}: TimelineProps) => {
    const [initialOpen, setInitialOpen] = useState<boolean>(true);

    const onTimelineSelected = (idx: number) => {
        if (initialOpen) {
            setInitialOpen(false)
        } else {
            onItemSelected(idx)
        }
    }
    return <div style={{ width: '100%', height: '100px' }}>
        <Chrono
            activeItemIndex={defaultChronoIndex}
            items={items}
            allowDynamicUpdate
            cardLess
            disableToolbar={true}
            hideControls
            onItemSelected={(item: any) => { onTimelineSelected(item.index) }}
            theme={{
                primary: '#001D53',
                secondary: 'none',
                titleColor: '#2D3335',
                titleColorActive: '#CC0000',
                iconBackgroundColor: '#FFFFFF'

            }}
            mode="HORIZONTAL" >
            <div className="chrono-icons">
                {items.map((item) => {
                    if (item.status === "STATUS_FLOATING") {
                        return <CloudCircle></CloudCircle>

                    } else if (item.validity === "VALIDITY_VALID") {
                        return <PublishedWithChanges></PublishedWithChanges>

                    } else {
                        return <Dangerous></Dangerous>
                    }
                })}
            </div>
        </Chrono>
    </div>
}
export default Timeline;