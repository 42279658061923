import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";

export interface SubmitProps {
    downloadText: string;
    submitText: string;
    onClickDownload: () => void;
    onClickSubmit: () => void;
}

function SubmitButtonsSingleRunErrorDialog({
    submitText,
    onClickSubmit,
    downloadText,
    onClickDownload
}: SubmitProps) {
    return (
        <>
            <Button variant="contained" onClick={onClickDownload}>
                <Download sx={{ mr: 1 }}></Download>
                {downloadText}
            </Button>
            <Button variant="contained" onClick={onClickSubmit}>
                {submitText}
            </Button>
        </>
    );
}

export default SubmitButtonsSingleRunErrorDialog;