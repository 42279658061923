import { useEffect } from 'react';
import { Box, Button, Divider } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StatusSelect, getStatusValues } from '../../../../models/historization';
import SubmitButtonsHistory from '../../../../shared/components/standard-actions/submit-buttons/submit-button-history';
import CrudTable, { ColumnDefinitionType } from '../../../../shared/components/crud-table/crud-table';
import { Sort } from '../../../../hooks/useSort.hook';
import { SortingDirection } from '../../../../enums/sort-by';
import { CaringPersonAPI, CaringPersonSelect } from '../../../../models/caring-person';
import { createPersonConnection, fetchPersonConnection, fetchHistoryForPersonConnection, fetchSingleHistoryForPersonConnection, updatePersonConnection } from '../../../../shared/requests/calculation-basis.requests';
import { PersonInNeedOfCareAPI, PersonInNeedOfCareSelect } from '../../../../models/person-in-need-of-care';
import { fetchHistoryForPersonInNeedOfCare, fetchPersonsInNeedOfCare } from '../../../../shared/requests/person-in-need-of-care.requests';
import { fetchCaringPersons, fetchHistoryForCaringPerson } from '../../../../shared/requests/caring-person.requests';
import { } from "../../../../redux/auth.reducer";
import AccordionComponent from '../../../../shared/components/accordion-component';
import { endingDateSameOrAfterEffectiveDate } from '../../../../helper/validation';
import { PersonConnectionAPI, PersonConnectionAPIRequest } from '../../../../models/person-connection';
import PersonConnectionForm from '../form/person-connection-form';
import { AssistWalker, Person } from '@mui/icons-material';
import CalculationBasisForm from '../form/calculation-basis-form';
import { CalculationBasisAmountFlagSelect, CalculationBasisAPI } from '../../../../models/calculation-basis';
import InputErrorDialog from '../../../../shared/components/standard-actions/dialogs/input-error-dialog';
import { cancelProcess, saveProcess } from '../../../../shared/requests/user.requests';
import { addToHistory, clearHistory, getHistory, HistoryEntry, HistoryTimelineItemModel, updateHistoryUntilIndex } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';
import { formatTimestampString, formatTimeStampUserString } from '../../../../helper/formatter';
import SubmitButtonsShow from '../../../../shared/components/standard-actions/submit-buttons/submit-button-show';

function PersonConnection() {
    //Select Listen
    const [historyData, setHistoryData] = useState<PersonConnectionAPI[]>([]);
    const [statusValues, setStatusValues] = useState<StatusSelect[]>([]);
    const [caringPersonValues, setCaringPersonValues] = useState<CaringPersonSelect[]>([]);
    const [personInNeedOfCareValues, setPersonInNeedOfCareValues] = useState<PersonInNeedOfCareSelect[]>([]);
    //Berechnungsgrundlage extra Daten
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [endingDate, setEndingDate] = useState<string | null>("");
    const [amount, setAmount] = useState<number>(0.0);
    const [amountFlag, setAmountFlag] = useState<CalculationBasisAmountFlagSelect | null>(null);
    const [caringPerson, setCaringPerson] = useState<CaringPersonSelect | null>(null);
    const [personInNeedOfCare, setPersonInNeedOfCare] = useState<PersonInNeedOfCareSelect | null>(null);
    const [note, setNote] = useState<string | null>("");
    const [calculationBasisList, setCalculationBasisList] = useState<CalculationBasisAPI[]>([]);
    const [earliestEffectiveDatePersonInNeedOfCare, setEarliestEffectiveDatePersonInNeedOfCare] = useState<string>("");
    const [earliestEffectiveDateCaringPerson, setEarliestEffectiveDateCaringPerson] = useState<string>("");

    //Systemdaten
    const [isShowMode, setShowMode] = useState<boolean>(false);
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const { id, history, processId } = useParams();
    const [showInputErrorDialog, setShowInputErrorDialog] = useState<boolean>(false);
    const [personInNeedOfCareDisabled, setPersonInNeedOfCareDisabled] = useState<boolean>(false);
    const [caringPersonDisabled, setCaringPersonDisabled] = useState<boolean>(false);
    const [pathToLastPersonOverview, setPathToLastPerson] = useState<string>("");
    const [leavePath, setLeavePath] = useState<string>("/");
    const [selectedHistoryIndex, setSelectedHistoryIndex] = useState<number>(-1);
    const errorConnection: boolean = caringPerson === null || personInNeedOfCare === null
    const errorCalculationBasis: boolean = effectiveDate === "" || endingDate === "" || !endingDateSameOrAfterEffectiveDate(endingDate, effectiveDate) || amount < 0 || amountFlag === null || effectiveDate < earliestEffectiveDateCaringPerson || effectiveDate < earliestEffectiveDatePersonInNeedOfCare;
    const errorInput: boolean = errorConnection

    const navigate = useNavigate();

    const goBackToHome = () => {
        navigate(`/`);
    }

    const goBackToLastPerson = () => {
        if (pathToLastPersonOverview !== null && pathToLastPersonOverview !== "") {
            navigate(pathToLastPersonOverview);
        } else {
            navigate("/")
        }
    }

    useEffect(() => {
        const showMode: boolean = window.location.pathname.includes("/show/")
        setShowMode(showMode)
        setStatusValues(getStatusValues());
        let setPersonInNeedOfCareToId: string | null = null;
        let setCaringPersonToId: string | null = null;
        if (id) {
            setPersonInNeedOfCareDisabled(true)
            setCaringPersonDisabled(true)
            if (!history) {
                fetchSinglePersonAPI(id, showMode);
            } else {
                fetchSinglePersonHistoryAPI(id, history, showMode);
            }
        } else {
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                const history = addToHistory({
                    type: "Berechnungsgrundlage",
                    name: `Neue Berechnungsgrundlage`,
                    url: window.location.pathname,
                    id: "-1"
                });
                history.forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url,
                        id: element.id
                    })
                }))
                setHistoryChronoItems(historyData);
                setCurrentRendered(true);
                if (history[history.length - 2].type === "Bedürftige/r") {
                    setPersonInNeedOfCareToId = history[history.length - 2].id;
                    setPathToLastPerson("/person-in-need-of-care");
                } else if (history[history.length - 2].type === "Pflegeperson") {
                    setCaringPersonToId = history[history.length - 2].id;
                    setPathToLastPerson("/caring-person");
                }
            }
        }
        fetchPersonsInNeedOfCare().then((response) => {
            let personsInNeedOfCare = response.data;
            let personsPinocSelect: CaringPersonSelect[] = [];
            personsInNeedOfCare.forEach((person) => {
                personsPinocSelect.push({
                    id: String(person.id),
                    name: person.person.lastname + ", " + person.person.firstname,
                    realValue: +person.id,
                    genderId: person.person.gender
                })
            })
            if (setPersonInNeedOfCareToId !== null) {
                const person = personsPinocSelect.find(({ id }) => String(id) === String(setPersonInNeedOfCareToId))
                if (person) {
                    setPersonInNeedOfCare(person);
                    setPersonInNeedOfCareDisabled(true)
                    setEarliestEffectiveDateForPersonInNeedOfCareWithID(person.id)
                }
            }
            setPersonInNeedOfCareValues(personsPinocSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }))
        });
        fetchCaringPersons().then((response) => {
            let caringPersons = response.data;
            let personsCPSelect: CaringPersonSelect[] = [];
            caringPersons.forEach((person) => {
                personsCPSelect.push({
                    id: String(person.id),
                    name: person.person.lastname + ", " + person.person.firstname,
                    realValue: +person.id,
                    genderId: person.person.gender
                })
            })
            if (setCaringPersonToId !== null) {
                const person = personsCPSelect.find(({ id }) => String(id) === String(setCaringPersonToId))
                if (person) {
                    setCaringPerson(person);
                    setCaringPersonDisabled(true)
                    setEarliestEffectiveDateForCaringPersonWithID(person.id)
                }
            }
            setCaringPersonValues(personsCPSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }))
        });
        setEffectiveDate("")
        setEndingDate("")

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, history, processId]);

    const getUserTitle = (): string => {
        const createUserTitle = "Berechnungsgrundlage erstellen";
        const editUserTitle = "Berechnungsgrundlage bearbeiten";
        const showTitle = "Berechnungsgrundlage anzeigen";
        if (isShowMode) {
            return showTitle
        }
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createEmployeeText = "Erstellen";
        const editEmployeeText = "Speichern";
        if (id) {
            return editEmployeeText;
        }
        return createEmployeeText;
    };

    const fetchSinglePersonAPI = (id: string, showMode: boolean) => {
        fetchPersonConnection(id).then((response) => {
            workWithFetchedPersonConnectionData(response.data, showMode)
        });
    }

    const fetchSinglePersonHistoryAPI = (id: string, historyNumber: string, showMode: boolean) => {
        fetchSingleHistoryForPersonConnection(id, historyNumber).then((response) => {
            workWithFetchedPersonConnectionData(response.data, showMode)
        });
    }

    const workWithFetchedPersonConnectionData = (person: PersonConnectionAPI | PersonConnectionAPI, showMode: boolean) => {
        fillPersonConnectionFields(person)
        fetchHistoryData(person.id, showMode)
        if (!isCurrentRendered) {
            const historyData: HistoryTimelineItemModel[] = [];
            const history = addToHistory({
                type: "Berechnungsgrundlage",
                name: `${person.personInNeedOfCare.person.lastname}, ${person.personInNeedOfCare.person.firstname} | ${person.caringPerson.person.lastname}, ${person.caringPerson.person.firstname}`,
                url: window.location.pathname,
                id: person.id
            })
            history.forEach((element => {
                historyData.push({
                    type: element.type,
                    title: element.name,
                    url: element.url,
                    id: element.id
                })
            }))
            setHistoryChronoItems(historyData);
            if (history[history.length - 2].type === "Bedürftige/r") {
                setPathToLastPerson("/person-in-need-of-care");
            } else if (history[history.length - 2].type === "Pflegeperson") {
                setPathToLastPerson("/caring-person");
            }
            setCurrentRendered(true);
        }
    }

    const fillPersonConnectionFields = (person: PersonConnectionAPI | PersonConnectionAPI) => {
        const cp = {
            id: person.caringPerson.id,
            name: person.caringPerson.person.lastname + ", " + person.caringPerson.person.firstname,
            realValue: +person.caringPerson.id,
            genderId: person.caringPerson.person.gender
        }
        const pinoc = {
            id: person.personInNeedOfCare.id,
            name: person.personInNeedOfCare.person.lastname + ", " + person.personInNeedOfCare.person.firstname,
            realValue: +person.personInNeedOfCare.id,
            genderId: person.personInNeedOfCare.person.gender
        };
        setCaringPerson(cp);
        setPersonInNeedOfCare(pinoc);
        setNote(person.note)
        if (pinoc != null) {
            setEarliestEffectiveDateForPersonInNeedOfCareWithID(pinoc.id)
        }
        if (cp != null) {
            setEarliestEffectiveDateForCaringPersonWithID(cp.id)
        }
        setCalculationBasisList(person.calculationBasisList.sort((a, b) => a.from > b.from ? 1 : -1))

    }

    const fetchHistoryData = (id: string, showMode: boolean) => {
        fetchHistoryForPersonConnection(id).then((response2) => {
            const data: PersonConnectionAPI[] = []
            response2.data.forEach(element => {
                if (element.status === "STATUS_FLOATING" && !history && !showMode) {
                    const newHistory: HistoryEntry[] = getHistory();
                    newHistory.pop();
                    localStorage.history = JSON.stringify(newHistory);
                    navigate(`/calculation-basis/edit/${id}/${element.historyNumber}/${processId}`)

                }
                data.push(element);
                if (element.deleteTimestamp === null) {
                    element.deleteTimestamp = "";
                }
            })
            setHistoryData(data);
        });
    }

    const setPersonInNeedOfCareValue = (value: string) => {
        const person: PersonInNeedOfCareSelect | null = personInNeedOfCareValues.find(({ name }) => name === value) || null
        setPersonInNeedOfCare(person)
        if (person != null) {
            setEarliestEffectiveDateForPersonInNeedOfCareWithID(person.id)
        }
    }

    const setCaringPersonValue = (value: string) => {
        const person: CaringPersonSelect | null = caringPersonValues.find(({ name }) => name === value) || null
        setCaringPerson(person)
        if (person != null) {
            setEarliestEffectiveDateForCaringPersonWithID(person.id)
        }
    }

    const setEarliestEffectiveDateForCaringPersonWithID = (id: string) => {
        fetchHistoryForCaringPerson(id).then((response2) => {
            const response: CaringPersonAPI[] = response2.data.filter(a => a.deleteTransaction === null).sort((a, b) => {
                if (a.effectiveDate.toLowerCase() < b.effectiveDate.toLowerCase()) { return -1; }
                if (a.effectiveDate.toLowerCase() > b.effectiveDate.toLowerCase()) { return 1; }
                return 0;
            })
            if (response.length >= 1) {
                setEarliestEffectiveDateCaringPerson(response[0].effectiveDate)
            }
        })
    }

    const setEarliestEffectiveDateForPersonInNeedOfCareWithID = (id: string) => {
        fetchHistoryForPersonInNeedOfCare(id).then((response2) => {
            const response: PersonInNeedOfCareAPI[] = response2.data.filter(a => a.deleteTransaction === null && a.validity === "VALIDITY_VALID").sort((a, b) => {
                if (a.effectiveDate.toLowerCase() < b.effectiveDate.toLowerCase()) { return -1; }
                if (a.effectiveDate.toLowerCase() > b.effectiveDate.toLowerCase()) { return 1; }
                return 0;
            })
            if (response.length >= 1) {
                setEarliestEffectiveDatePersonInNeedOfCare(response[0].effectiveDate)
            }
        })
    }



    const savePersonConnection = (status: StatusSelect | null, processSave: boolean, historyClear: boolean, route?: string) => {
        if (!isShowMode) {
            const caringPersonId = caringPerson ? caringPerson.id : "-1";
            const personInNeedOfCareId = personInNeedOfCare ? personInNeedOfCare.id : "-1";
            const stat = status ? status.id : "";
            const processIdentifier = processId ? processId : "-1"
            const personConnection: PersonConnectionAPIRequest = {
                id: id ? id : "-1",
                status: stat,
                caringPersonId,
                personInNeedOfCareId,
                calculationBasisList: calculationBasisList,
                processIdentifier,
                note

            };
            setSavePushed(true);
            if (!errorInput && status !== null) {
                if (id) {
                    updatePersonConnection(personConnection).then((response) => {
                        if (processSave === true && processId) {
                            saveProcess(processId).then(() => {
                                maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                            })
                        } else {
                            if (route) {
                                navigate(route);
                            }
                            else {
                                maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                            }
                        }
                    }).catch(console.error);
                } else {
                    createPersonConnection(personConnection).then((response) => {
                        if (processSave === true && processId) {
                            saveProcess(processId).then(() => {
                                maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                            })
                        } else {
                            maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                        }
                    }).catch(console.error);
                }
            }
        }
    };

    const maybeClearHistoryAndNavigateRoute = (historyClear: boolean, person: PersonConnectionAPI, route?: string) => {
        if (historyClear) {
            clearHistory()
        }
        const history = getHistory();
        if (history.length !== 0 && history[history.length - 1].id === "-1") {
            updateHistoryUntilIndex(history.length - 1);
            const url = processId ? `/calculation-basis/edit/${person.id}/${processId}/process` : `/calculation-basis/edit/${person.id}`
            addToHistory({
                type: "Berechnungsgrundlage",
                name: `${person.personInNeedOfCare.person.lastname}, ${person.personInNeedOfCare.person.firstname} | ${person.caringPerson.person.lastname}, ${person.caringPerson.person.firstname}`,
                url: url,
                id: person.id
            })
        }
        if (route) {
            navigate(route);
        }
        else {
            goBackToHome()
        }
    }

    const onClickHistoryShow = (person: PersonConnectionAPI) => {
        if (isShowMode) {
            navigate(`/calculation-basis/show/${id}/history/${person.historyNumber}`)
        } else {
            onClickSaveAndOpenPath(`/calculation-basis/edit/${id}/${processId}/process/show/${person.historyNumber}`)
        }
    }

    const onClickCaringPersonEdit = () => {
        if (caringPerson) {
            if (isShowMode) {
                navigate(`/caring-person/show/${caringPerson.id}`)
            } else {
                if (processId) {
                    onClickSaveAndOpenPath(`/caring-person/edit/${caringPerson.id}/${processId}`)
                } else {
                    onClickSaveAndOpenPath(`/caring-person/edit/${caringPerson.id}`)
                }
            }
        }
    }

    const onClickPersonInNeedOfCareEdit = () => {
        if (personInNeedOfCare) {
            if (isShowMode) {
                navigate(`/person-in-need-of-care/show/${personInNeedOfCare.id}`)
            } else {
                if (processId) {
                    onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personInNeedOfCare.id}/${processId}`)
                } else {
                    onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personInNeedOfCare.id}`)
                }
            }
        }
    }

    const onClickSaveAndOpenPath = (path: string) => {
        if (!isShowMode) {
            if (!errorInput) {
                savePersonConnection(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, false, path);
            } else {
                setLeavePath(path);
                setShowInputErrorDialog(true);
            }
        }
    }

    const onSave = () => {
        if (!isShowMode) {
            let path: string | undefined = undefined;
            let clearHistory: boolean = false;
            if (historyChronoItems.length > 1) {
                if (historyChronoItems[historyChronoItems.length - 2].type === "Pflegeperson") {
                    path = `/caring-person/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
                else if (historyChronoItems[historyChronoItems.length - 2].type === "Bedürftige/r") {
                    path = `/person-in-need-of-care/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
                else if (historyChronoItems[historyChronoItems.length - 2].type === "Berechnungsgrundlage") {
                    path = `/calculation-basis/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
            }
            savePersonConnection(statusValues.find(({ id }) => id === "STATUS_EFFECTIVE") || null, true, clearHistory, path)
        }
    }

    const onEditCancel = () => {
        if (!isShowMode) {
            if (processId) {
                cancelProcess(processId).then(() => { goBackToLastPerson() });
            } else {
                goBackToLastPerson()
            }
        }
    }

    const actions = (
        <SubmitButtonsHistory
            processId={processId}
            submitText={getSavingButtonText()}
            cancelText="Verwerfen"
            pauseText="Unterbrechen"
            onClickSubmit={onSave}
            onClickCancel={onEditCancel}
            onClickPause={() => {
                savePersonConnection(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, false);
            }}
        />
    )

    const onClickBack = () => {
        if (historyChronoItems.length > 1) {
            const path: string = historyChronoItems[historyChronoItems.length - 2].url
            updateHistoryUntilIndex(historyChronoItems.length - 2)
            navigate(path)
        } else {
            goBackToHome();
        }
    }

    const actionsShow = (
        <SubmitButtonsShow
            backText="Zurück"
            onClickBack={onClickBack}
        />
    );

    const onLeaveSiteCancel = () => {
        const historyData: HistoryTimelineItemModel[] = [];
        getHistory().forEach(element => {
            historyData.push({
                type: element.type,
                title: element.name,
                url: element.url,
                id: element.id
            })
        })
        setHistoryChronoItems(historyData)
        setShowInputErrorDialog(false);
    };

    const onLeaveSiteSubmit = () => {
        setShowInputErrorDialog(false);
        if (selectedHistoryIndex >= 0) {
            updateHistoryUntilIndex(selectedHistoryIndex)
        }
        navigate(leavePath);
    };

    const columns: ColumnDefinitionType<PersonConnectionAPI, keyof PersonConnectionAPI>[] = [
        {
            key: "historyNumber",
            header: "Historiennummer",
        },
        {
            key: "createTimestamp",
            header: "Zeitstempel Erstellung",
        },
        {
            key: "createDescriptionString",
            header: "Beschreibung Erstellung",
        },
        {
            key: "deleteTimestamp",
            header: "Zeitstempel Löschung",
        },
        {
            key: "deleteDescriptionString",
            header: "Beschreibung Löschung",
        },
    ];

    const [sortBy] = useState<Sort<CaringPersonAPI>>({
        key: "historyNumber",
        direction: SortingDirection.Ascending,
    });

    return (
        <PageLayout title={getUserTitle()} actions={isShowMode ? actionsShow : actions}>
            <InputErrorDialog
                showDialog={showInputErrorDialog}
                dialogTitle="Berechnungsgrundlageneintrag löschen?"
                dialogInfo={`Die/Der Bedürftige/r kann nicht gespeichert werden, da das Formular nicht richtig ausgefüllt wurde. Soll die Seite trotzdem verlassen werden?`}
                onClickCancel={onLeaveSiteCancel}
                onClickSubmit={() => onLeaveSiteSubmit()}
            />
            <div className="single-view">
                <HistoryLine
                    items={historyChronoItems}
                    setCurrentRendered={setCurrentRendered}
                    saveAndOpenPath={isShowMode ? undefined : onClickSaveAndOpenPath}
                    errorInput={errorInput}
                    setShowInputErrorDialog={setShowInputErrorDialog}
                    setLeavePath={setLeavePath}
                    setSelectedIndex={setSelectedHistoryIndex}
                    setHistoryChronoItems={setHistoryChronoItems}
                />

                <PersonConnectionForm
                    caringPerson={caringPerson}
                    caringPersonValues={caringPersonValues}
                    disabled={isShowMode}
                    personInNeedOfCareDisabled={personInNeedOfCareDisabled}
                    caringPersonDisabled={caringPersonDisabled}
                    personInNeedOfCare={personInNeedOfCare}
                    personInNeedOfCareValues={personInNeedOfCareValues}
                    savePushed={savePushed}
                    setCaringPersonValue={setCaringPersonValue}
                    setPersonInNeedOfCareValue={setPersonInNeedOfCareValue}
                    earliestEffectiveDateCaringPerson={earliestEffectiveDateCaringPerson}
                    earliestEffectiveDatePersonInNeedOfCare={earliestEffectiveDatePersonInNeedOfCare}
                    setEarliestEffectiveDateCaringPerson={setEarliestEffectiveDateCaringPerson}
                    setEarliestEffectiveDatePersonInNeedOfCare={setEarliestEffectiveDatePersonInNeedOfCare}
                    note={note}
                    setNote={setNote}
                    formError={errorConnection}
                    validation
                />

                <CalculationBasisForm
                    disabled={isShowMode}
                    formError={errorCalculationBasis}
                    dateFrom={effectiveDate}
                    dateUntil={endingDate}
                    amount={amount}
                    amountFlag={amountFlag}
                    setDateFrom={setEffectiveDate}
                    setDateUntil={setEndingDate}
                    setAmount={setAmount}
                    setAmountFlag={setAmountFlag}
                    calculationBasisList={calculationBasisList}
                    setCalculationBasisList={setCalculationBasisList}
                    earliestEffectiveDateCaringPerson={earliestEffectiveDateCaringPerson}
                    earliestEffectiveDatePersonInNeedOfCare={earliestEffectiveDatePersonInNeedOfCare}
                />
                <Divider variant='fullWidth' sx={{ mt: 3, mb: 3 }} ></Divider>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="auto"
                    sx={{ '& button': { mr: 10, mt: 2 } }}>

                    {caringPerson !== null && <Button startIcon={<Person />} variant="contained" size="large" onClick={onClickCaringPersonEdit}
                        sx={{
                            color: "#001D53",
                            background: "#D4E7FF",
                            ':hover': {
                                background: 'primary.main',
                                color: 'white',
                            },
                        }}>{`Pflegeperson ${isShowMode ? "anzeigen" : "bearbeiten"}`}</Button>}
                    {personInNeedOfCare !== null && <Button startIcon={<AssistWalker />} variant="contained" size="large" onClick={onClickPersonInNeedOfCareEdit}
                        sx={{
                            color: "#001D53",
                            background: "#D4E7FF",
                            ':hover': {
                                background: 'primary.main',
                                color: 'white',
                            },
                        }}>{`Bedürftigen ${isShowMode ? "anzeigen" : "bearbeiten"}`}</Button>}
                </Box>
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Historie der Berechnungsgrundlage"
                            defaultExpanded={false}
                            content={
                                <CrudTable
                                    tableData={historyData}
                                    columns={columns}
                                    sortBy={sortBy}
                                    fetchElementsWithId={fetchHistoryForPersonConnection}
                                    dataTypeName="Historieneintrag"
                                    dataFormatter={(element: PersonConnectionAPI) => {
                                        element.createTimestamp = formatTimeStampUserString(element.createTimestamp, element.createTransaction.user)
                                        if (element.deleteTimestamp === null || element.deleteTransaction === null) {
                                            if (element.deleteTimestamp === null) {
                                                element.deleteTimestamp = "";
                                            } else {
                                                element.deleteTimestamp = formatTimestampString(element.deleteTimestamp)
                                            }
                                        } else {
                                            element.deleteTimestamp = formatTimeStampUserString(element.deleteTimestamp, element.deleteTransaction.user)
                                        }
                                        element.createDescriptionString = element.createTransaction.description;
                                        element.deleteDescriptionString = element.deleteTransaction ? element.deleteTransaction?.description : ""
                                        return element;
                                    }}
                                    callOnFindInPageClick={onClickHistoryShow}
                                    hideSearchbar
                                    searchingId={id}
                                />
                            }
                        />
                    </div>
                }
            </div>
        </PageLayout>
    );
}

export default PersonConnection;
