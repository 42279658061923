
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

export interface SubmitProps {
    backText: string;
    onClickBack: () => void;
}

function SubmitButtonsShow({
    backText,
    onClickBack,
}: SubmitProps) {
    return (
        <>

            <Button variant="contained" onClick={onClickBack}>
                <ArrowBack sx={{ mr: 1 }}></ArrowBack>
                {backText}
            </Button>
        </>

    );
}

export default SubmitButtonsShow;
