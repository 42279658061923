import { resourceAxiosInstance } from "../../config/axios.config";
import { AidConfigAPI, AidConfigAPIRequest, CalculationBasisChangerAPI, CalculationBasisChangerAPIRequest, CalculationExportApiRequest, CalculationRunAPI } from "../../models/aid";

export const fetchAidConfig = (institutionId: string) =>
    resourceAxiosInstance.get<AidConfigAPI>(`/aid/${institutionId}/configuration`, {
        cache: false,
    });

export const updateAidConfig = (aidConfig: Partial<AidConfigAPIRequest>) =>
    resourceAxiosInstance.patch<AidConfigAPIRequest>(
        `/aid/${aidConfig.institutionId}/configuration`,
        aidConfig,
        {
            cache: false,
        }
    );

export const startCalculationRuns = (institutionId: string) =>
    resourceAxiosInstance.post(`/aid/${institutionId}/calc-run`, {
        cache: false,
    });

export const fetchAllCalculationRuns = (institutionId: string) =>
    resourceAxiosInstance.get<CalculationRunAPI[]>(`/aid/${institutionId}/calc-run`, {
        cache: false,
    });

export const fetchABVReportForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/abv`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchDRVReportForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/drv`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchAVReportForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/av`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchAVDetailReportForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/av/detail`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchCalculationRunCSVExport = (institutionId: string, data: CalculationExportApiRequest) =>
    resourceAxiosInstance.post<any>(`/aid/${institutionId}/calc-run/report`, data, {
        cache: false,
    });

export const startCalculationBasisChanger = (institutionId: string, changeParameters: CalculationBasisChangerAPIRequest) =>
    resourceAxiosInstance.post(`/aid/${institutionId}/calculation-basis-changer`, changeParameters, {
        cache: false,
    });

export const fetchAllCalculationBasisChanger = (institutionId: string) =>
    resourceAxiosInstance.get<CalculationBasisChangerAPI[]>(`/aid/${institutionId}/calculation-basis-changer`, {
        cache: false,
    });