import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAlKHrbCPEYF-eA5oirxj6wA6DoP31MRp0",
    authDomain: "aidplus.firebaseapp.com",
    projectId: "aidplus",
    storageBucket: "aidplus.firebasestorage.app",
    messagingSenderId: "490301988336",
    appId: "1:490301988336:web:ddd35e3f249f65215d9135"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);