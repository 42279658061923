import { useEffect, useRef, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import { PersonInNeedOfCareAPI, PersonInNeedOfCareSort } from "../../../../models/person-in-need-of-care";
import CrudTable, {
    ColumnDefinitionType,
} from "../../../../shared/components/crud-table/crud-table";
import {
    makePersonInNeedOfCareInvalid,
    fetchPersonsInNeedOfCare,
    fetchPersonInNeedOfCareReport,
} from "../../../../shared/requests/person-in-need-of-care.requests";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { } from "../../../../redux/auth.reducer";
import { useNavigate } from "react-router-dom";
import InvalidDialog from "../../../../shared/components/standard-actions/dialogs/invalid-dialog";
import { clearHistory } from "../../../../models/history";
import { formatAddressString, formatCurrentDateForFileOutput, formatDateString, formatPersonName, formatStatusString, formatValidityString } from "../../../../helper/formatter";
import { saveAs } from 'file-saver';
import { InvalidAPIRequest } from "../../../../models/person";
import { hasEditPersonDataPermission, hasGetPersonDataPermission } from "../../../../helper/validation";

function ListPersonsInNeedOfCare() {
    const [sortBy] = useState<Sort<PersonInNeedOfCareSort>>({
        key: "id",
        direction: SortingDirection.Descending,
    });
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const [persons, setPersons] = useState<PersonInNeedOfCareAPI[]>([]);
    const navigate = useNavigate();
    const isInitRender = useRef(true);
    const [showInvalidDialog, setShowInvalidDialog] = useState<boolean>(false);
    const [entity, setEntity] = useState<PersonInNeedOfCareAPI | null>(null);
    const [clickedId, setClickedId] = useState<string>("");

    const onShowClick = (entity: PersonInNeedOfCareAPI) => {
        navigate(`show/${entity.id}`);
    }

    const onAddClick = () => {
        navigate('add');
    }
    const onEditClick = (entity: PersonInNeedOfCareAPI) => {
        navigate(`edit/${entity.id}`);
    }
    const onInvalidClick = (entity: PersonInNeedOfCareAPI) => {
        setEntity(entity)
        setClickedId(entity.id)
        setShowInvalidDialog(true)
    }
    const onInvalidCancel = () => {
        setShowInvalidDialog(false)
    }

    const onDownloadReportClicked = (entity: PersonInNeedOfCareAPI) => {
        fetchPersonInNeedOfCareReport(entity.id).then(response => {
            saveAs(response.data, `${formatCurrentDateForFileOutput()}_Bedürftigen_Report_${entity.person.lastname}_${entity.person.firstname}`);
        })
    }

    const onInvalidSubmit = (value: InvalidAPIRequest | null) => {
        if (value !== null) {
            makePersonInNeedOfCareInvalid(clickedId ?? "", value).then((response) => {
                if (response.status === 200) {
                    setShowInvalidDialog(false)
                }
            });
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        clearHistory();
        if (isInitRender.current) {
            fetchPersonsInNeedOfCare().then((response) => {
                setPersons(response.data);
            });
            isInitRender.current = false;
        }
    });

    const columns: ColumnDefinitionType<PersonInNeedOfCareAPI, keyof PersonInNeedOfCareAPI>[] = [
        {
            key: "id",
            header: "ID",
        },

        {
            key: "personName",
            header: "Name, Vorname, Geburtsdatum",
        },

        {
            key: "addressString",
            header: "Anschrift",
        },

        {
            key: "effectiveDate",
            header: "Wirksamkeitsdatum",
        },
        {
            key: "conceptOfOrder1",
            header: "Ord. Begriff1",
        },
        {
            key: "validityString",
            header: "Gültigkeit",
        },
        {
            key: "statusString",
            header: "Status ",
        },
    ];
    const hasEditPermission = (person: PersonInNeedOfCareAPI | null) => {
        if (user) {
            return hasEditPersonDataPermission(user?.permissions, person ? person.contractType : null);
        } else {
            return false;
        }
    }

    const hasGetPermission = (person: PersonInNeedOfCareAPI | null) => {
        if (user) {
            return hasGetPersonDataPermission(user?.permissions, person ? person.contractType : null);
        } else {
            return false;
        }
    }
    return (
        <PageLayout title={"Übersicht Bedürftige | Aktueller Zustand"}>
            <InvalidDialog
                showDialog={showInvalidDialog}
                dialogDatatypeName={"eines Bedürftigen"}
                dataTypeName="die/der Bedürftige/r"
                personFirstName={entity?.person.firstname}
                personLastName={entity?.person.lastname}
                personGender={entity?.person.gender}
                personDateOfBirth={entity?.person.dateOfBirth}
                onClickCancel={onInvalidCancel}
                onClickSubmit={(value: InvalidAPIRequest | null) => onInvalidSubmit(value)}
            />
            <CrudTable
                callOnAddClick={onAddClick}
                callOnFindInPageClick={onShowClick}
                callOnEditClick={onEditClick}
                callOnInactiveClick={onInvalidClick}
                callOnDownloadClick={onDownloadReportClicked}
                getPermission={hasGetPermission}
                editPermission={hasEditPermission}
                dataTypeName="Bedürftigen"
                changeFloatingText
                showGender
                tableData={persons}
                columns={columns}
                sortBy={sortBy}
                fetchElements={fetchPersonsInNeedOfCare}
                dataFormatter={(element: PersonInNeedOfCareAPI) => {
                    element.effectiveDate = formatDateString(element.effectiveDate)
                    element.personName = formatPersonName(element.person)
                    element.addressString = formatAddressString(element.address)
                    element.validityString = formatValidityString(element.validity)
                    element.statusString = formatStatusString(element.status, element.createTransaction.user)
                    return element;
                }}
            />
        </PageLayout>
    );
}

export default ListPersonsInNeedOfCare;
