import { GenderType, getGenderType } from "../../../models/person";
import TextFieldComponent from "../text-field-component";
import { Person } from "@mui/icons-material";
import { dateOfBirthValid, isValidPensionInsuranceNumber } from "../../../helper/validation";
import AccordionComponent from "../accordion-component";
import SelectComponent from "../select-component";

type SelectProps = {
    savePushed: boolean;
    disabled: boolean;
    lastname: string;
    setLastname: (value: string) => void;
    firstname: string;
    setFirstname: (value: string) => void;
    dateOfBirth: string | null;
    setDateOfBirth: (value: string | null) => void;
    gender: GenderType | null;
    setGender: (value: GenderType | null) => void;
    namePrefix: string | null;
    setNamePrefix: (value: string) => void;
    title: string | null;
    setTitle: (value: string) => void;
    pensionInsuranceNumber: string | null;
    setPensionInsuranceNumber: (value: string) => void;
    pensionInsuranceNumberRequired?: boolean;
    usePensionInsuranceNumber?: boolean
    formError: boolean;
    validation?: boolean
    setValuesChanged?: (val: boolean) => void;
};

const PersonForm = ({
    savePushed,
    disabled,
    lastname,
    setLastname,
    firstname,
    setFirstname,
    dateOfBirth,
    setDateOfBirth,
    gender,
    setGender,
    namePrefix,
    setNamePrefix,
    title,
    setTitle,
    pensionInsuranceNumber,
    setPensionInsuranceNumber,
    pensionInsuranceNumberRequired,
    usePensionInsuranceNumber,
    formError,
    validation,
    setValuesChanged
}: SelectProps) => {

    let genderValues: GenderType[] = getGenderType();

    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={true}
            label="Personendaten"
            startIcon={<Person></Person>}
            error={formError}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={false}
                                id="title"
                                label="Titel"
                                value={title}
                                error={false}
                                helperText=""
                                type='text'
                                disabled={disabled}
                                setValueString={setTitle}
                                setValueChanged={setValuesChanged}
                            />
                            <TextFieldComponent
                                required={false}
                                id="namePrefix"
                                label="Namensvorsatz"
                                value={namePrefix}
                                error={false}
                                helperText=""
                                type='text'
                                disabled={disabled}
                                setValueString={setNamePrefix}
                                setValueChanged={setValuesChanged}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="lastname"
                                label="Nachname"
                                value={lastname}
                                error={lastname === "" && savePushed}
                                helperText={
                                    lastname === "" && savePushed
                                        ? "Der Nachname darf nicht leer sein!"
                                        : ""
                                }
                                type='text'
                                disabled={disabled}
                                setValueString={setLastname}
                                setValueChanged={setValuesChanged}
                            />
                            <TextFieldComponent
                                required={true}
                                id="firstname"
                                label="Vorname"
                                value={firstname}
                                error={firstname === "" && savePushed}
                                helperText={
                                    firstname === "" && savePushed
                                        ? "Der Vorname darf nicht leer sein!"
                                        : ""
                                }
                                type='text'
                                disabled={disabled}
                                setValueString={setFirstname}
                                setValueChanged={setValuesChanged}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={false}
                                id="dateOfBirth"
                                label="Geburtsdatum"
                                value={dateOfBirth}
                                error={dateOfBirth != null && dateOfBirth !== "" && !dateOfBirthValid(dateOfBirth)}
                                helperText={dateOfBirth != null && dateOfBirth !== "" && !dateOfBirthValid(dateOfBirth)
                                    ? "Das eingebene Geburtsdatum stimmt nicht mit den Richtlinien überein!"
                                    : ""
                                }
                                type='date'
                                disabled={disabled}
                                setValueString={setDateOfBirth}
                                setValueChanged={setValuesChanged}
                            />
                            <SelectComponent
                                selectData={genderValues}
                                tooltip="Es wurden noch keine Geschlechts-Typen angelegt."
                                className="single-view__text-field"
                                label="Geschlecht"
                                value={gender?.name || ""}
                                genderSelect
                                onChange={(value: string) => { setGender(genderValues.find(({ name }) => name === value) || null); }}
                                disabled={disabled}
                                error={!gender && savePushed}
                                helperText={!gender && savePushed ? "Das Geschlecht muss ausgwählt werden!" : ""}
                                setValueChanged={setValuesChanged}
                            ></SelectComponent>

                        </div>
                        {usePensionInsuranceNumber &&
                            <div className="flex flex-row single-view__form-row-width single-view-empty-container">
                            </div>
                        }
                        {usePensionInsuranceNumber &&
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={pensionInsuranceNumberRequired ? pensionInsuranceNumberRequired : false}
                                    id="pensionInsuranceNumber"
                                    label="Rentenversicherungsnummer"
                                    value={pensionInsuranceNumber}
                                    error={pensionInsuranceNumber != null && pensionInsuranceNumber !== "" && !isValidPensionInsuranceNumber(pensionInsuranceNumber, dateOfBirth, gender)}
                                    helperText={
                                        pensionInsuranceNumber != null && pensionInsuranceNumber !== "" && !isValidPensionInsuranceNumber(pensionInsuranceNumber, dateOfBirth, gender)
                                            ? "Die eingegebene Rentenversicherungsnummer ist keine gültige Rentenversicherungsnummer!"
                                            : ""
                                    }
                                    type='text'
                                    disabled={disabled}
                                    setValueString={setPensionInsuranceNumber}
                                    setValueChanged={setValuesChanged}
                                />
                                <div className="single-view__empty-form-width"></div>
                            </div>
                        }
                    </div >
                </div>
            }
        />
    </div>
}
export default PersonForm;