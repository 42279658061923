import { Button } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import "../details.css"
import CrudTable, { ColumnDefinitionType } from "../../../shared/components/crud-table/crud-table";
import { Sort } from "../../../hooks/useSort.hook";
import { SortingDirection } from "../../../enums/sort-by";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { } from "../../../redux/auth.reducer";
import PageLayout from "../../../shared/components/page-layout";
import { Calculate } from "@mui/icons-material";
import { clearHistory } from "../../../models/history";
import { fetchABVReportForCalculationRun, fetchAllCalculationRuns, fetchAVDetailReportForCalculationRun, fetchAVReportForCalculationRun, fetchDRVReportForCalculationRun, startCalculationRuns } from "../../../shared/requests/aid-request";
import { CalculationRunAPI, CalculationRunSort } from "../../../models/aid";
import TextFieldComponent from "../../../shared/components/text-field-component";
import AccordionComponent from "../../../shared/components/accordion-component";
import { dateBeforeOrSameAsCurrentDate, endingDateSameOrAfterEffectiveDate } from "../../../helper/validation";
import ConfirmCancelDialog from "../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog";
import { formatCurrentDateForFileOutput, formatTimestampString, formatYearMonthForFileOutput } from "../../../helper/formatter";
import { saveAs } from 'file-saver';
import { fetchInstitution } from "../../../shared/requests/institution.requests";
import ErrorDialog from "../../../shared/components/standard-actions/dialogs/error-dialog";
import SingleRunErrorDialog from "../../../shared/components/standard-actions/dialogs/single-run-error-dialog";

function CalculationRun() {
    const [calculationRuns, setCalculationRuns] = useState<CalculationRunAPI[]>([]);
    const [externalModifiedCalculationRuns, setExternalModifiedCalculationRuns] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<string>("");
    const [dateUntil, setDateUntil] = useState<string>("");
    const [searchAcivated, setSearchActivated] = useState<boolean>(false);
    const [showConfirmStartRunDialog, setShowConfirmStartRunDialog] = useState<boolean>(false);
    const [showRunErrorDialog, setShowRunErrorDialog] = useState<boolean>(false);
    const [showSingleRunErrorDialog, SetShowSingleRunErrorDialog] = useState<boolean>(false);
    const [singleRunErrors, setSingleRunErrors] = useState<string[] | null>(null);
    const isInitRender = useRef(true);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const errorSearch = dateUntil === "" || !dateBeforeOrSameAsCurrentDate(dateUntil) || !endingDateSameOrAfterEffectiveDate(dateUntil, dateFrom) || dateFrom === "";
    const currentDate = new Date();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isInitRender.current && user) {
            clearHistory();
            fetchAllCalculationRuns(user.institution.id).then((response) => {
                setCalculationRuns(response.data)
            })
            isInitRender.current = false;
        }
        setDateFrom(currentDate.getFullYear() + "-" + ((currentDate.getMonth() + 1) < 10 ? "0" : "") + (currentDate.getMonth() + 1) + "-01")
        setDateUntil(currentDate.getFullYear() + "-" + ((currentDate.getMonth() + 1) < 10 ? "0" : "") + (currentDate.getMonth() + 1) + "-" + ((currentDate.getDate()) < 10 ? "0" : "") + (currentDate.getDate()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const columns: ColumnDefinitionType<CalculationRunAPI, keyof CalculationRunAPI>[] = [
        {
            key: "startString",
            header: "Start des Laufs",
        },
        {
            key: "endString",
            header: "Ende des Laufs",
        },
        {
            key: "statusString",
            header: "Status",
        },
        {
            key: "startUserString",
            header: "Start durch User",
        },
        {
            key: "amountCalculatedCaringPerson",
            header: "Anzahl berechneter Pflegepersonen",
        },
        {
            key: "rvString",
            header: "Gesamtbeitrag (D)RV",
        },
        {
            key: "abvString",
            header: "Gesamtbeitrag ABV",
        },
        {
            key: "avString",
            header: "Gesamtbeitrag AV",
        },
        {
            key: "amountToPayString",
            header: "Enstandene Kosten",
        },
    ]

    const [sortBy] = useState<Sort<CalculationRunSort>>({
        key: "startString",
        direction: SortingDirection.Descending,
    });

    const onSearchPressed = () => {
        setSearchActivated(true);
        setExternalModifiedCalculationRuns(true)
    }

    const onStartRunClicked = () => {
        if (user) {
            fetchInstitution(user.institution.id).then(response => {
                const currentDate: Date = new Date();
                let latestRunDate: Date | null = null;
                if (response.data.latestRunDate !== null) {
                    latestRunDate = new Date(response.data.latestRunDate)
                }
                if (latestRunDate === null || latestRunDate.getFullYear() < currentDate.getFullYear() || (latestRunDate.getFullYear() === currentDate.getFullYear() && latestRunDate.getMonth() < (currentDate.getMonth() - 1))) {
                    setShowConfirmStartRunDialog(true)
                } else {
                    setShowRunErrorDialog(true)
                }
            })
        }
    }

    const onStartRunCancel = () => {
        setShowConfirmStartRunDialog(false)
        setShowRunErrorDialog(false)
        SetShowSingleRunErrorDialog(false)
    }

    const onStartRunSubmit = () => {
        if (user) {
            startCalculationRuns(user.institution.id).then(() => {
                setShowConfirmStartRunDialog(false)
                setExternalModifiedCalculationRuns(true)
            })
        }
    }

    const onClickDownloadABVReport = (data: CalculationRunAPI) => {
        if (user) {
            fetchABVReportForCalculationRun(user.institution.id, data.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_ABV_Beitragsuebersicht_${formatYearMonthForFileOutput(data.startTimestamp)}`);
            })
        }
    }

    const onClickDownloadDRVReport = (data: CalculationRunAPI) => {
        if (user) {
            fetchDRVReportForCalculationRun(user.institution.id, data.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_DRV_Beitragsnachweis_${formatYearMonthForFileOutput(data.startTimestamp)}`);
            })
        }
    }

    const onClickDownloadAVReport = (data: CalculationRunAPI) => {
        if (user) {
            fetchAVReportForCalculationRun(user.institution.id, data.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_AV_Beitragsnachweis_${formatYearMonthForFileOutput(data.startTimestamp)}`);
            })
            fetchAVDetailReportForCalculationRun(user.institution.id, data.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_AV_Beitragsuebersicht_${formatYearMonthForFileOutput(data.startTimestamp)}`);
            })
        }
    }

    const onClickShowError = (data: CalculationRunAPI) => {
        setSingleRunErrors(data.runErrors)
        SetShowSingleRunErrorDialog(true)
    }

    const onClickReload = (data: CalculationRunAPI) => {
        setExternalModifiedCalculationRuns(true);
    }



    return <PageLayout title={"Beitragsberechnungsläufe"}>
        <ConfirmCancelDialog
            dialogTitle="Starten eines Beitragsberechnungslauf"
            dialogInfo={`Soll ein neuer Beitragsberechnungslauf gestartet werden, der alle noch nicht berechneten Monate bis 
                einschließlich ${currentDate.getMonth() !== 0 ? currentDate.getMonth() : 12}/${currentDate.getMonth() !== 0 ? currentDate.getFullYear() : (currentDate.getFullYear() - 1)} berechnet?`}
            onClickCancel={onStartRunCancel}
            onClickSubmit={onStartRunSubmit}
            showDialog={showConfirmStartRunDialog}

        />
        <ErrorDialog
            dialogInfo={`Es wurde bereits ein Lauf für alle Monate einschließlich ${currentDate.getMonth() !== 0 ? currentDate.getMonth() : 12}/${currentDate.getMonth() !== 0 ? currentDate.getFullYear() : (currentDate.getFullYear() - 1)} gestartet. Daher kann zur Zeit kein neuer Berechnungslauf gestartet werden!`}
            onClickSubmit={onStartRunCancel}
            showDialog={showRunErrorDialog}
        />
        <SingleRunErrorDialog
            runErrors={singleRunErrors}
            onClickSubmit={onStartRunCancel}
            showDialog={showSingleRunErrorDialog}
        />
        {user && user.permissions.includes("calculation:start") &&
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="auto"
                sx={{ '& button': { mr: 10, mt: 2 } }}>
                <Button startIcon={<Calculate />} variant="contained" size="large" onClick={onStartRunClicked}
                    sx={{
                        height: 150,
                        width: 300,
                        color: "#001D53",
                        background: "#D4E7FF",
                        ':hover': {
                            background: 'primary.main', // theme.palette.primary.main
                            color: 'white',
                        },
                    }}>Beitragsberechnungslauf starten</Button>
            </Box>
        }
        <div className="single-view">
            <div className='single-view-accordion'>
                <AccordionComponent
                    defaultExpanded={true}
                    label="Berechnungsläufe"
                    startIcon={<Calculate></Calculate>}
                    validation={false}
                    error={false}
                    content={
                        <div className="flex flex-column single-view-person__data-container">
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="effectiveDate"
                                    label="Von-Datum"
                                    type='date'
                                    value={dateFrom}
                                    error={dateFrom === ""}
                                    helperText={
                                        dateFrom === ""
                                            ? "Das Von-Datum darf nicht leer sein!"
                                            : ""
                                    }
                                    disabled={false}
                                    setValueString={setDateFrom}
                                />
                                <TextFieldComponent
                                    required={false}
                                    id="effectiveDate"
                                    label="Bis-Datum"
                                    type='date'
                                    value={dateUntil}
                                    error={dateUntil === "" || !dateBeforeOrSameAsCurrentDate(dateUntil) || !endingDateSameOrAfterEffectiveDate(dateUntil, dateFrom)}
                                    helperText={
                                        dateFrom === ""
                                            ? "Das Bis-Datum darf nicht leer sein!"
                                            : !dateBeforeOrSameAsCurrentDate(dateUntil)
                                                ? "Das Bis-Datum darf nicht hinter dem aktuellen Datum sein!"
                                                : !endingDateSameOrAfterEffectiveDate(dateUntil, dateFrom)
                                                    ? "Das Bis-Datum darf nicht vor dem Von-Datum liegen!"
                                                    : ""
                                    }
                                    disabled={false}
                                    setValueString={setDateUntil}
                                />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <div className="single-view__empty-form-width"></div>
                                <Button variant="outlined" size="large" onClick={onSearchPressed}
                                >Suchen</Button>
                            </div>
                            <div className='single-view-accordion'>
                                <CrudTable
                                    tableData={calculationRuns}
                                    callOnDownloadABVClick={onClickDownloadABVReport}
                                    callOnDownloadDRVClick={onClickDownloadDRVReport}
                                    callOnDownloadAVClick={onClickDownloadAVReport}
                                    callOnGetErrorsClick={onClickShowError}
                                    callOnReloadClick={onClickReload}
                                    externalModified={externalModifiedCalculationRuns}
                                    setExternalModified={setExternalModifiedCalculationRuns}
                                    columns={columns}
                                    sortBy={sortBy}
                                    fetchElementsWithId={fetchAllCalculationRuns}
                                    searchingId={user ? user.institution.id : "-1"}
                                    hideSearchbar
                                    dataFormatter={(element: CalculationRunAPI) => {
                                        element.abvString = element.sumABV + "€";
                                        element.avString = element.sumAV + "€";
                                        element.rvString = element.sumRV + "€";
                                        element.amountToPayString = (element.amountToPay ? element.amountToPay : "0") + "€";
                                        element.startUserString = element.runStartUser.lastname + ", " + element.runStartUser.firstname
                                        element.statusString = element.status === "E_RUN_COLLECTION_STATUS_RUNNING" ? "In Arbeit" : element.status === "E_RUN_COLLECTION_STATUS_FAILED" ? "Fehlgeschlagen" : "Abgeschlossen";
                                        element.startString = formatTimestampString(element.startTimestamp)
                                        element.endString = formatTimestampString(element.endTimestamp)
                                        return element;
                                    }}
                                    filterData={(element: CalculationRunAPI) => {
                                        if (!searchAcivated || errorSearch) {
                                            return true;
                                        } else {
                                            const dateSearchFrom = new Date(dateFrom);
                                            const dateSearchUntil = new Date(dateUntil);
                                            const dateStarted = new Date(element.startTimestamp);
                                            dateStarted.setHours(0)
                                            dateStarted.setMinutes(0)
                                            dateStarted.setSeconds(0)
                                            if (dateSearchFrom !== null && dateSearchUntil !== null && dateStarted !== null) {
                                                return dateStarted >= dateSearchFrom && dateStarted <= dateSearchUntil
                                            }
                                        }
                                        return false
                                    }}
                                />
                            </div>
                        </div>

                    }></AccordionComponent>
            </div>
        </div>
    </PageLayout>
}

export default CalculationRun;