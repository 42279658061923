import "./maintenance.css";
export default function Maintenance() {
    return (
        <div className="maintenance-container">
            <img
                src="/assets/img/AidPlus_Logo_transparent.png"
                alt="img not found"
                className="maintenance-img"
            />
            <h1 className="maintenance-h1">AidPlus befindet sich zur Zeit in der Wartung!</h1>
            <h2 className="maintenance-h2">Betriebszeiten: Montags-Freitags 06:00-22:00 Uhr</h2>
            <h2 className="maintenance-h2">Kontaktieren Sie uns in wichtigen Fällen unter <a href="mailto:info@aidplus.eu" className="maintenance-h2">info@aidplus.eu</a></h2>
            <h4 className="maintenance-h4">(Zum Aktualisieren die Seite neuladen)</h4>
        </div>
    );
}