import { axiosInstance, resourceAxiosInstance } from "../../config/axios.config";
import { InstitutionAPI, InstitutionAPIRequest } from "../../models/institution";

export const fetchInstitutions = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution`, {
        id: "/institutions-without-system"
    });

export const fetchInstitutionsWithSystem = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?system=true`, {
        id: "/institutions-with-system"
    });

export const fetchInstitutionsWithSystemWithoutPensionInstitutions = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?system=true&pensionInstitutions=false`, {
        id: "/institutions-with-system-without-pensions"
    });

export const fetchInsurances = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=false&insurances=true&pensionInstitutions=false`, {
        id: "/insurances"
    });

export const fetchAids = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=true&insurances=false&pensionInstitutions=false`, {
        id: "/aids"
    });

export const fetchPensionInstitutions = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=false&insurances=false&pensionInstitutions=true`, {
        id: "/pension-instituions"
    });

export const fetchAidsAndInsurances = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=true&insurances=true&pensionInstitutions=false`, {
        id: "/aids-insurances"
    });


export const importAids = (content: string) => {
    return axiosInstance.post(`/institution/import/aid`, content, {
        cache: {
            update: {
                "/aids": "delete",
                "/insurances": "delete",
                "/institutions-with-system-without-pensions": "delete",
                "/institutions-with-system": "delete",
                "/institutions-without-system": "delete",
                "/pension-instituions": "delete",
                "/aids-insurances": "delete",
            }
        },
        headers: {
            'Content-Type': 'text/plain'
        },
    },);
}

export const importInsurances = (content: string) => {
    return axiosInstance.post(`/institution/import/insurance`, content, {
        cache: {
            update: {
                "/aids": "delete",
                "/insurances": "delete",
                "/institutions-with-system-without-pensions": "delete",
                "/institutions-with-system": "delete",
                "/institutions-without-system": "delete",
                "/pension-instituions": "delete",
                "/aids-insurances": "delete",
            }
        },
        headers: {
            'Content-Type': 'text/plain'
        },
    },);
}

export const importPensionInstitutions = (content: string) => {
    return axiosInstance.post(`/institution/import/pension-institution`, content, {
        cache: {
            update: {
                "/aids": "delete",
                "/insurances": "delete",
                "/institutions-with-system-without-pensions": "delete",
                "/institutions-with-system": "delete",
                "/institutions-without-system": "delete",
                "/pension-instituions": "delete",
                "/aids-insurances": "delete",
            }
        },
        headers: {
            'Content-Type': 'text/plain'
        },
    },);
}

export const updateInstitution = (institution: Partial<InstitutionAPIRequest>) =>
    resourceAxiosInstance.patch<InstitutionAPIRequest>(
        `/institution/${institution.id}`,
        institution,
        {
            cache: {
                update: {
                    "/aids": "delete",
                    "/insurances": "delete",
                    "/institutions-with-system-without-pensions": "delete",
                    "/institutions-with-system": "delete",
                    "/institutions-without-system": "delete",
                    "/pension-instituions": "delete",
                    "/aids-insurances": "delete",
                }
            },
        }
    );

export const changeActiveOfInstitution = (institutionId: string | undefined) =>
    resourceAxiosInstance.put<InstitutionAPIRequest>(
        `/institution/${institutionId}/active`,
        {
            cache: {
                update: {
                    "/aids": "delete",
                    "/insurances": "delete",
                    "/institutions-with-system-without-pensions": "delete",
                    "/institutions-with-system": "delete",
                    "/institutions-without-system": "delete",
                    "/pension-instituions": "delete",
                    "/aids-insurances": "delete",
                }
            },
        }
    );

export const deleteInstitution = (institutionId: string | undefined) =>
    resourceAxiosInstance.delete(`/institution/${institutionId}`, {
        cache: {
            update: {
                "/aids": "delete",
                "/insurances": "delete",
                "/institutions-with-system-without-pensions": "delete",
                "/institutions-with-system": "delete",
                "/institutions-without-system": "delete",
                "/pension-instituions": "delete",
                "/aids-insurances": "delete",
            }
        },
    });

export const fetchInstitution = (institutionId: string) =>
    resourceAxiosInstance.get<InstitutionAPI>(`/institution/${institutionId}`, {
        cache: false,
    });

export const createInstitution = (institution: Partial<InstitutionAPIRequest>) =>
    resourceAxiosInstance.post<InstitutionAPIRequest>(`/institution`, institution, {
        cache: {
            update: {
                "/aids": "delete",
                "/insurances": "delete",
                "/institutions-with-system-without-pensions": "delete",
                "/institutions-with-system": "delete",
                "/institutions-without-system": "delete",
                "/pension-instituions": "delete",
                "/aids-insurances": "delete",
            }
        },
    });
