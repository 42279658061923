import { Chrono } from "react-chrono";
import { AssistWalker, Error, Insights, ManageSearch, Person } from "@mui/icons-material";
import { getHistory, HistoryEntry, HistoryTimelineItemModel } from "../../models/history";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

type TimelineProps = {
    items: HistoryTimelineItemModel[];
    errorInput?: boolean;
    setCurrentRendered?: (value: boolean) => void;
    saveAndOpenPath?: (path: string) => void;
    setShowInputErrorDialog?: (value: boolean) => void;
    setLeavePath?: (value: string) => void;
    setSelectedIndex?: (value: number) => void;
    setHistoryChronoItems?: (items: HistoryTimelineItemModel[]) => void
};

const HistoryLine = ({
    items,
    errorInput,
    setCurrentRendered,
    saveAndOpenPath,
    setShowInputErrorDialog,
    setLeavePath,
    setSelectedIndex,
    setHistoryChronoItems
}: TimelineProps) => {

    const [initialOpen, setInitialOpen] = useState<boolean>(true);

    const navigate = useNavigate();


    const onHistoryItemSelected = (idx: number) => {
        if (initialOpen) {
            setInitialOpen(false)
        } else {
            if (setSelectedIndex) {
                setSelectedIndex(idx)
            }
            const newHistory: HistoryEntry[] = [];
            let link = "";
            getHistory().forEach((element, index) => {
                if (index < idx) {
                    newHistory.push(element)
                }
                if (index === idx) {
                    link = element.url
                }
            })
            if (errorInput === undefined || errorInput === null || errorInput === false) {
                localStorage.history = JSON.stringify(newHistory);
                if (saveAndOpenPath) {
                    saveAndOpenPath(link)
                } else {
                    navigate(link);
                }
                if (setCurrentRendered) {
                    setCurrentRendered(false)
                }
            } else {
                if (setShowInputErrorDialog && setLeavePath && setHistoryChronoItems) {
                    setHistoryChronoItems([])
                    setInitialOpen(true)
                    setShowInputErrorDialog(true)
                    setLeavePath(link)
                }
            }
        }
    }

    return <div style={{ width: '100%', height: '100px' }}>
        <Chrono
            items={items}
            allowDynamicUpdate
            cardLess
            disableToolbar={true}
            hideControls
            activeItemIndex={items.length - 1}
            onItemSelected={(item: any) => { onHistoryItemSelected(item.index) }}
            theme={{
                primary: '#001D53',
                secondary: 'none',
                titleColor: '#2D3335',
                titleColorActive: '#CC0000',
                iconBackgroundColor: '#FFFFFF'

            }}
            mode="HORIZONTAL"
            fontSizes={{
                title: '0.8rem',
            }} >
            <div className="chrono-icons">
                {items.map((item) => {
                    if (item.type === "Bedürftige/r") {
                        return <AssistWalker></AssistWalker>

                    } else if (item.type === "Pflegeperson") {
                        return <Person></Person>

                    } else if (item.type === "Berechnungsgrundlage") {
                        return <Insights></Insights>
                    } else if (item.type === "Historie") {
                        return <ManageSearch></ManageSearch>
                    } else {
                        return <Error></Error>
                    }
                })}
            </div>
        </Chrono>
    </div >
}
export default HistoryLine;